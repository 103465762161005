import { Component, createRef } from "react";
import { InputNumber, Select, Row, Col } from "antd";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

const { Option } = Select;

export default class ValueTypeEditor extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    this.item = createRef();
    this.state = {
      value: _.get(value, "value"),
      valueType: _.get(value, "valueType") || "value",
    };
  }

  renderOptions() {
    const options = [
      {
        label: "Value",
        value: "value",
      },
      {
        label: "Percentage",
        value: "percentage",
      },
    ];
    return options.map((option) => (
      <Option key={option.value} value={option.value}>
        {option.label}
      </Option>
    ));
  }

  getValue() {
    return this.state;
  }

  onChangeValue = (value) => {
    this.setState({ value });
  };

  onChangeType = (valueType) => {
    this.setState({ valueType });
  };

  render() {
    const { value, valueType } = this.state;
    return (
      <Row className="editor-value-type">
        <Col span={12}>
          <InputNumber
            size="small"
            placeholder="Value"
            onChange={this.onChangeValue}
            value={value}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={12}>
          <Select
            showSearch
            optionFilterProp="children"
            mode="single"
            style={{ width: "100%" }}
            placeholder={<FormattedMessage id="type" />}
            defaultValue="value"
            value={valueType}
            onChange={this.onChangeType}
            size="small"
          >
            {this.renderOptions()}
          </Select>
        </Col>
      </Row>
    );
  }
}
