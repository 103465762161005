import _ from "lodash";

import { moment } from "../../../helpers/moment";
import { roundTo2DecimalPlaces } from "../../../helpers";
import translate from "../../../helpers/translate";
import { hotelsCRMURI } from "../../../config";

export default translate({
  reservationsArchivePage: {
    columnDefs: [
      {
        headerName: "actions",
        field: "actions",
        hide: false,
        headerCheckboxSelectionFilteredOnly: true,
        cellRenderer: "actionsRenderer",
        cellRendererParams: (params) => {
          const { _hotel, _id, publicId } = params.data;
          return {
            link: `${hotelsCRMURI}${_hotel.slug}/reservations/${_id}/${publicId}?portal=true`,
          };
        },
        width: 100,
        resizable: false,
        suppressSizeToFit: true,
        pinned: "left",
        filter: false,
        sortable: false,
        floatingFilter: false,
      },
      {
        headerName: "hotel",
        field: "_hotel.name",
        pinned: "left",
      },
      {
        headerName: "name",
        field: "name",
        cellRenderer: (params) =>
          `${params.data.reservationLeader.firstName} ${params.data.reservationLeader.lastName}`,
      },
      {
        headerName: "from",
        field: "from",
        cellRenderer: ({ value }) =>
          !value ? null : moment(value).format("L"),
        resizable: false,
        suppressSizeToFit: true,
        sort: "asc",
      },
      {
        headerName: "to",
        field: "to",
        cellRenderer: ({ value }) =>
          !value ? null : moment(value).format("L"),
      },
      {
        headerName: "created",
        field: "_createdOn",
        cellRenderer: ({ value }) =>
          !value ? null : moment(value).format("L"),
      },
      {
        headerName: "rooms",
        field: "rooms",
        cellRenderer: ({ value }) => _.sumBy(value, "numOfRooms"),
      },
      {
        headerName: "pax",
        field: "pax",
        cellRenderer: (params) =>
          `${_.sumBy(params.data.rooms, "adults") || 0}
          /${_.sumBy(params.data.rooms, (i) => i.children.length) || 0}
          /${_.sumBy(params.data.rooms, "infants") || 0}`,
      },
      {
        headerName: "status",
        field: "status",
        cellRenderer: "statusRenderer",
        pinned: "right",
      },
      {
        headerName: "id",
        field: "_id",
      },
      {
        headerName: "cooperation",
        field: "cooperation.officialName",
      },
      {
        pinned: "right",
        headerName: "amount",
        field: "amount",
        cellRenderer: ({ value }) => `€${roundTo2DecimalPlaces(value) || 0}`,
      },
    ],
    defaultColDefs: {
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
    },
  },
});
