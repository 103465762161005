import React from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const allViews = Object.keys(Views).map(k => Views[k]);

const BigCalendar = ({ defaultDate, draggableAccessor, events, eventPropGetter, height, onDoubleClickEvent, onSelectSlot, resizableAccessor, tooltipAccessor, views }) => {
  return (
    <div className="bigCalendar">
      <DnDCalendar
        defaultDate={defaultDate}
        defaultView="month"
        events={events}
        views={views || allViews}
        eventPropGetter={eventPropGetter}
        localizer={localizer}
        onDoubleClickEvent={onDoubleClickEvent}
        onSelectSlot={onSelectSlot}
        draggableAccessor={() => draggableAccessor || false} //  Disables Drag
        resizableAccessor={() => resizableAccessor || false} //  Disables Resize
        tooltipAccessor={() => tooltipAccessor || null}
        resizable={false}
        selectable={true}
        style={{ height: height || 800 }}
        popupOffset={{ x: 30, y: 20 }}
        popup={true}
      />
    </div>
  );
}

export default BigCalendar;
