import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import {
  PlusOutlined,
  CopyOutlined,
  ReconciliationOutlined,
  UndoOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { Menu, Dropdown } from "antd";
import { v4 as uuid } from "uuid";
import _ from "lodash";

import { sendNotification } from "../../redux/actions";

//  Custom Renderers
import BooleanRenderer from "./renderers/BooleanRenderer";
import LinkRenderer from "./renderers/LinkRenderer";
import StatusRenderer from "./renderers/StatusRenderer";

//  Custom Editors
import BooleanEditor from "./editors/BooleanEditor";
import DateRangeEditor from "./editors/DateRangeEditor";
import MultiSelectEditor from "./editors/MultiSelectEditor";
import NumericEditor from "./editors/NumericEditor";
import SingleSelectEditor from "./editors/SingleSelectEditor";
import ValueTypeEditor from "./editors/ValueTypeEditor";
import DoubleEditor from "./editors/DoubleEditor";
import NumericRangeEditor from "./editors/NumericRangeEditor";

const EditableTable = (props) => {
  const intl = useIntl();
  const {
    columnDefs,
    defaultColDefs,
    defaultValues,
    disableOptions,
    doesExternalFilterPass,
    headerHeight,
    isExternalFilterPresent,
    property,
    rowData,
    tableOperations,
    setAPI,
    rowHeight,
    rowSelection,
    onRowSelected,
    onSelectionChanged,
  } = props;
  const dispatch = useDispatch();

  const [gridAPI, setGridApi] = useState(null);

  useEffect(() => {
    if (property === "periods" && !_.isEmpty(gridAPI)) gridAPI.redrawRows();
  }, [rowData]);

  const addNewRow = () => {
    const _id = uuid();
    const defaultValue = {
      _id,
      new: true,
      ...defaultValues,
    };
    if (property === "rooms.roomtypes") {
      defaultValue.roomtypeId = _id;
    }
    tableOperations.onDataChanged([...(rowData || []), defaultValue], property);
  };

  const onCellValueChanged = (params) => {
    tableOperations.onDataChanged(rowData, property);
    autoSizeAll(params, false);
  };

  const allFields = columnDefs.map((c) => c.field);

  const deleteRows = () => {
    const selectedRows = gridAPI.getSelectedRows();
    const ids = new Set(selectedRows.map((x) => x._id));
    tableOperations.onDataChanged(
      rowData.filter((data) => !ids.has(data._id)),
      property
    );
  };

  const onRestore = () => tableOperations.onRestore(property);

  const onRowDragMove = (event) => {
    const movingNode = event.node;
    const overNode = event.overNode;
    const rowNeedsToMove = movingNode !== overNode;
    if (rowNeedsToMove) {
      const movingData = movingNode.data;
      const overData = overNode.data;
      const newRowData = rowData.map((row) => {
        if (row === movingData) return overData;
        else if (row === overData) return movingData;
        else return row;
      });
      tableOperations.onDataChanged(newRowData, property);
    }
  };

  const onCopyRows = () => {
    const selectedRows = gridAPI.getSelectedRows();
    if (!selectedRows.length)
      return dispatch(
        sendNotification({
          type: "error",
          message: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "noRowsSelected" }),
        })
      );
    dispatch(
      sendNotification({
        type: "success",
        message: intl.formatMessage({ id: "success" }),
        description: `${selectedRows.length} ${intl.formatMessage({ id: "successfullyCopied" })}`,
      })
    );
    window.localStorage.setItem("copy", JSON.stringify({ selectedRows, name: property }));
    gridAPI.deselectAll();
  };

  const onPasteRows = () => {
    const copyItems = JSON.parse(window.localStorage.getItem("copy"));
    if (_.isEmpty(copyItems)) return;
    const { selectedRows, name } = copyItems;
    if (!selectedRows || !selectedRows.length)
      return dispatch(
        sendNotification({
          type: "error",
          message: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "noRowsSelected" }),
        })
      );
    let matchedRows;
    if (name === property) {
      // same table
      matchedRows = selectedRows;
    } else {
      // different table - trying to do the best we can to match them using the column names
      matchedRows = selectedRows
        .map((r) =>
          _.pickBy(
            r,
            (value, key) =>
              (allFields.includes(key) ||
                (allFields.includes(`${key}.0`) &&
                  allFields.filter((f) => f.startsWith(`${key}.`)).length >= value.length)) &&
              !_.isNil(value) &&
              value !== "" &&
              (!_.isObjectLike(value) || !_.isEmpty(value))
          )
        )
        .filter((r) => !_.isEmpty(r));
    }
    if (!matchedRows.length)
      return dispatch(
        sendNotification({
          type: "error",
          message: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "invalidRowsCopiedToTable" }),
        })
      );
    const matchedRowsWithIds = matchedRows.map((r) => {
      const newRow = { ...r, _id: uuid(), new: true };
      if (property === "rooms.roomtypes") {
        newRow.roomtypeId = newRow._id;
      }
      return newRow;
    });
    tableOperations.onDataChanged([...rowData, ...matchedRowsWithIds], property);
    dispatch(
      sendNotification({
        type: "success",
        message: intl.formatMessage({ id: "success" }),
        description: `${matchedRowsWithIds.length} ${intl.formatMessage({
          id: "successfullyPasted",
        })}`,
      })
    );
    // window.localStorage.removeItem("copy");
  };

  const onMenuClick = ({ key }) => {
    const keyToFun = { 1: addNewRow, 2: onCopyRows, 3: onPasteRows, 4: onRestore, 5: deleteRows };
    keyToFun[key]();
  };

  const menu = () => {
    const selectedRows = gridAPI.getSelectedRows();
    return (
      <Menu onClick={onMenuClick}>
        {!(disableOptions || []).includes("addRow") ? (
          <Menu.Item key="1">
            <PlusOutlined /> {intl.formatMessage({ id: "addRow" })}
          </Menu.Item>
        ) : null}
        {selectedRows.length > 0 ? (
          <Menu.Item key="2">
            <CopyOutlined /> {intl.formatMessage({ id: "copyRows" })}
          </Menu.Item>
        ) : null}
        {!(disableOptions || []).includes("pasteRows") ? (
          <Menu.Item key="3">
            <ReconciliationOutlined /> {intl.formatMessage({ id: "pasteRows" })}
          </Menu.Item>
        ) : null}
        {tableOperations.onRestore ? (
          <Menu.Item key="4">
            <UndoOutlined /> {intl.formatMessage({ id: "restoreRows" })}
          </Menu.Item>
        ) : null}
        {!(disableOptions || []).includes("deleteRows") ? (
          selectedRows.length ? (
            <Menu.Item key="5">
              <MinusOutlined /> {intl.formatMessage({ id: "deleteRows" })}
            </Menu.Item>
          ) : null
        ) : null}
      </Menu>
    );
  };

  function autoSizeAll(params, skipHeader) {
    if (params.columnApi) {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      params.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }
  }

  return (
    <Dropdown overlay={menu} trigger={["contextMenu"]}>
      <div className="ag-theme-balham" style={{ flex: 1, paddingTop: 12, paddingBottom: 24 }}>
        <AgGridReact
          defaultColDef={{ suppressMovable: true, ...defaultColDefs }}
          columnDefs={columnDefs}
          rowData={rowData || []}
          modules={AllCommunityModules}
          multiSortKey={"ctrl"}
          headerHeight={headerHeight}
          rowSelection={rowSelection || "multiple"}
          onRowSelected={onRowSelected}
          onSelectionChanged={onSelectionChanged}
          immutableData={true}
          doesExternalFilterPass={doesExternalFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
          rowDragManaged={true}
          rowHeight={rowHeight}
          suppressRowClickSelection={true}
          stopEditingWhenGridLosesFocus={false}
          getRowNodeId={(data) => data._id}
          onRowDragMove={onRowDragMove}
          onCellValueChanged={onCellValueChanged}
          domLayout="autoHeight"
          frameworkComponents={{
            //  Renderers
            booleanRenderer: BooleanRenderer,
            linkRenderer: LinkRenderer,
            statusRenderer: StatusRenderer,
            //  Editors
            booleanEditor: BooleanEditor,
            dateRangeEditor: DateRangeEditor,
            multiSelectEditor: MultiSelectEditor,
            doubleEditor: DoubleEditor,
            numericRangeEditor: NumericRangeEditor,
            numericEditor: NumericEditor,
            singleSelectEditor: SingleSelectEditor,
            valueTypeEditor: ValueTypeEditor,
          }}
          onGridReady={(params) => {
            setGridApi(params.api);
            if (setAPI) setAPI(params.api);
          }}
          onFirstDataRendered={(params) => autoSizeAll(params, false)}
        />
      </div>
    </Dropdown>
  );
};

export default EditableTable;
