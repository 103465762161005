import moment from "moment";
import _ from "lodash";

import translate from "../../../helpers/translate";
import  { hotelsCRMURI } from "../../../config";

const formatDate = (data) => data.value && moment(data.value).format("DD/MM/YYYY");
const formatDateTime = (data) => data.value && moment(data.value).format("DD/MM/YYYY HH:mm");

const requiredFunction = (params) =>
  (!params.value || (_.isObject(params.value) && _.isEmpty(params.value))) &&
  params.value !== false;

export default translate({
  archivePage: {
    columnDefs: [
      {
        headerName: "actions",
        field: "actions",
        pinned: "left",
        hide: false,
        cellRenderer: "actionsRenderer",
        cellRendererParams: (params) => {
          const { _hotel, _id, recipientId } = params.data;
          return {
            seenWarning: true,
            link: `${hotelsCRMURI}${_hotel.slug}/event/${_id}/${recipientId}?portal=true`,
          };
        },
        width: 100,
        resizable: false,
        suppressSizeToFit: true,
        filter: false,
        sortable: false,
        floatingFilter: false,
      },
      {
        headerName: "hotel",
        field: "_hotel.name",
      },
      {
        headerName: "cooperation",
        field: "cooperation",
      },
      {
        headerName: "title",
        field: "title",
      },
      {
        headerName: "arrival",
        field: "arrival",
        width: 80,
        cellRenderer: formatDate,
      },
      {
        headerName: "departure",
        field: "departure",
        width: 80,
        cellRenderer: formatDate,
      },
      {
        headerName: "releaseDate",
        field: "releaseDate",
        width: 80,
        cellRenderer: formatDate,
      },
      {
        headerName: "status",
        field: "status",
        cellRenderer: "statusRenderer",
        cellStyle: {
          textAlign: "center",
        },
        width: 120,
      },
      {
        headerName: "pax",
        field: "pax",
        cellEditor: "numericEditor",
        width: 120,
      },
      {
        headerName: "paid",
        field: "paid",
        cellRenderer: ({ data }) => _.sumBy(data.payments, (p) => p.amount),
      },
      {
        headerName: "sent",
        field: "sent",
        width: 120,
        cellRenderer: "sentRenderer",
      },
      {
        headerName: "seen",
        field: "seen",
        width: 120,
        cellRenderer: "seenRenderer",
      },
      {
        headerName: "createdOn",
        field: "_createdOn",
        width: 160,
        cellRenderer: formatDateTime,
      },
      {
        headerName: "files",
        field: "files",
        cellRenderer: "filesRenderer",
      },
    ],
    defaultColDefs: {
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
    },
  },
  attachments: {
    columnDefs: [
      {
        headerName: "id",
        field: "_id",
        hide: true,
      },
      {
        headerName: "description",
        field: "description",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        rowDrag: true,
        cellClassRules: {
          "error-required": requiredFunction,
        },
      },
      {
        headerName: "files",
        field: "files",
        editable: true,
        cellRenderer: "filesRenderer",
        cellEditor: "filesEditor",
      },
    ],
    defaultColDefs: { resizable: true, editable: true },
  },
});
