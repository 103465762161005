import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Tag } from "antd";
import { green, red } from "@ant-design/colors";
import moment from "moment";

export default class SeenRenderer extends Component {
  state = {
    value: this.props.value,
  };

  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({ value: params.value });
    }
    return true;
  }

  renderValue() {
    const { value } = this.state;
    if (value) return <Tag color={green.primary}>{moment(value).format("DD/MM/YYYY HH:mm")}</Tag>;
    return (
      <Tag color={red.primary}>
        <FormattedMessage id={"no"} />
      </Tag>
    );
  }

  render() {
    return <div>{this.renderValue()}</div>;
  }
}
