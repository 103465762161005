import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { Button } from "antd";
import { logout } from "../../redux/actions";

const LogoutButton = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const onClickLogout = () => {
    dispatch(logout());
    history.push("/login");
  };

  return (
    <Button key="logout" type="link" onClick={onClickLogout}>
      {intl.formatMessage({ id: "logout" })}
    </Button>
  );
};

export default LogoutButton;
