// Other Actions
export const CLEAR_DATA = "CLEAR_DATA";

//  Auth Actions
export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const SET_TOKEN = "SET_TOKEN";
export const FETCH_ME = "FETCH_ME";
export const RESET = "RESET";
export const LOGOUT = "LOGOUT";
export const HOTELSCRM_LOGIN = "HOTELSCRM_LOGIN";
export const CHECK_RESET_PASSWORD_TOKEN = "CHECK_RESET_PASSWORD_TOKEN";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SEND_RESET_PASSWORD_LINK = "SEND_RESET_PASSWORD_LINK";

//  System Actions
export const REDIRECT = "REDIRECT";
export const SEND_NOTIFICATION = "SEND-SEND_NOTIFICATION";
export const CHANGE_SYSTEM_LANGUAGE = "CHANGE_SYSTEM_LANGUAGE";

//  Config Actions
export const FETCH_CONFIG = "FETCH_CONFIG";
export const FETCH_PUBLIC_CONFIG = "FETCH_PUBLIC_CONFIG";
export const UPDATE_CONFIG = "UPDATE_CONFIG";

// User Actions
export const CREATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE_USER";
export const FETCH_USERS = "FETCH_USERS";
export const SEND_WELCOME_EMAIL_LINK = "SEND_WELCOME_EMAIL_LINK";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const UPDATE_USER = "UPDATE_USER";

//  CONTRACTS
export const CONTRACTS_SEARCH = "CONTRACTS_SEARCH";

//  OFFERS
export const OFFERS_SEARCH = "OFFERS_SEARCH";

//  RESTRICTIONS
export const RESTRICTIONS_SEARCH = "RESTRICTIONS_SEARCH";
export const RESTRICTIONS_CALENDAR_FETCH = "RESTRICTIONS_CALENDAR_FETCH";

// HOTELS
export const HOTELS_SEARCH = "HOTELS_SEARCH";

// GROUPS
export const GROUPS_SEARCH = "GROUPS_SEARCH";

// EVENTS
export const EVENTS_SEARCH = "EVENTS_SEARCH";

// RESERVATIONS
export const RESERVATION_CREATE = "RESERVATION_CREATE";
export const RESERVATION_FETCH = "RESERVATION_FETCH";
export const RESERVATIONS_SEARCH = "RESERVATIONS_FETCH";
export const RESERVATIONS_GET_PRICE = "RESERVATIONS_GET_PRICE";
export const RESERVATION_UPDATE = "RESERVATIONS_UPDATE";
