import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { Button, Col, Form, Input, Modal, notification, PageHeader, Popconfirm, Row, Table, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, MailOutlined } from "@ant-design/icons";
import { createUser, deleteUser, fetchUsers, sendWelcomeEmailLink, updateUser } from "../../redux/actions";
import { moment } from "../../helpers/moment";
import _ from "lodash";
import EditProfile from "../Profile/partials/EditProfile";


const UsersPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [usersData, setUsersData] = useState({
    data: [],
    total: 0,
    pageSize: 10,
    current: 1,
    filters: {},
  });
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [userModalVisible, setUserModalVisible] = useState(false);

  const [selectedUserContacts, setSelectedUserContacts] = useState([]);
  const tableOperations = { onDataChanged: (data) => setSelectedUserContacts(data) };

  const [modalForm] = Form.useForm();
  const [searchForm] = Form.useForm();

  const { data, filters, ...pagination } = usersData;

  const onSendWelcomeEmail = (id) => {
    dispatch(sendWelcomeEmailLink(id))
      .then(() => {
        notification.success({
          message: intl.formatMessage({ id: "welcomeEmailSended" }),
        });
        loadData();
      })
      .catch(() => {
        notification.error({
          message: intl.formatMessage({ id: "welcomeEmailError" }),
        });
      });
  };

  const DateRenderer = (date) => _.isNil(date) ? null : moment(date).format("DD/MM/YYYY");

  const columns = [
    {
      title: intl.formatMessage({ id: "actions" }),
      width: 120,
      render: (data) => (
        <div>
          <Tooltip title={intl.formatMessage({ id: "welcomeEmail" })}>
            <Button onClick={() => onSendWelcomeEmail(data._id)} type="link" icon={<MailOutlined />} />
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: "edit" })}>
            <Button onClick={onClickEdit(data)} type="link" icon={<EditOutlined />} />
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: "delete" })}>
            <Popconfirm
              placement="top"
              title={intl.formatMessage({
                id: "areYouSureYouWantToDeleteThis?",
              })}
              onConfirm={() => onDelete(data._id)}
              okText={intl.formatMessage({ id: "yes" })}
              cancelText={intl.formatMessage({ id: "no" })}
            >
              <Button type="link" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: "username" }),
      dataIndex: "username",
    },
    {
      title: intl.formatMessage({ id: "officialName" }),
      dataIndex: "officialName",
    },
    {
      title: intl.formatMessage({ id: "email" }),
      dataIndex: "email",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
    },
    {
      title: intl.formatMessage({ id: "portalId" }),
      dataIndex: "_portalId",
    },
    {
      title: intl.formatMessage({ id: "welcomeEmail" }),
      dataIndex: "welcomeEmailDate",
      render: (date) => DateRenderer(date),
    },
    {
      title: intl.formatMessage({ id: "forgotPassword" }),
      dataIndex: "resetPasswordDate",
      render: (date) => DateRenderer(date),
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const loadData = (current = 1, pageSize = 10, filters = {}) => {
    setLoading(true);
    return dispatch(fetchUsers(current, pageSize, filters)).then(({ payload }) => {
      setUsersData(payload);
      setLoading(false);
    });
  };

  const onDelete = (id) => {
    setLoading(true);
    dispatch(deleteUser(id))
      .then(() =>
        loadData(
          data.length === 1 ? Math.max(pagination.current - 1, 1) : pagination.current,
          pagination.pageSize,
          filters
        )
      )
      .finally(() => setLoading(false));
  };

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    loadData(current, pageSize, filters);
  };

  const onClickEdit = (data) => () => {
    setSelectedUserContacts(data.contacts);
    modalForm.setFieldsValue(data);
    setUserModalVisible(true);
  };

  const onOkUsersModal = async () => {
    try {
      await modalForm.validateFields();
      const values = modalForm.getFieldsValue();
      values.contacts = selectedUserContacts;
      setSubmitting(true);
      if (isEdit()) {
        await dispatch(updateUser(values, values._id));
      } else {
        await dispatch(createUser(values));
      }
      setSubmitting(false);
      onCancelUsersModal();
      resetSearch();
      setSelectedUserContacts([]);
    } catch {
      setSubmitting(false);
    }
  };

  const onCancelUsersModal = () => {
    modalForm.resetFields();
    setUserModalVisible(false);
  };

  const resetSearch = () => {
    searchForm.resetFields();
    loadData();
  };

  const submitSearch = () => {
    const values = _.omitBy(searchForm.getFieldsValue(), _.isNil);
    if (!_.isEmpty(values)) {
      loadData(1, 10, values);
    }
  };

  const isEdit = () => !_.isEmpty(modalForm.getFieldValue("_id"));

  return (
    <div>
      <Modal
        title={
          isEdit() ? intl.formatMessage({ id: "editUser" }) : intl.formatMessage({ id: "newUser" })
        }
        visible={userModalVisible}
        onOk={onOkUsersModal}
        okButtonProps={{ disabled: submitting }}
        onCancel={onCancelUsersModal}
        cancelButtonProps={{ disabled: submitting }}
        width="80vw"
      >
        <EditProfile
          adminFields={true}
          tableOperations={tableOperations}
          contacts={selectedUserContacts}
          form={modalForm}
        />
      </Modal>
      <div style={{ width: "100%" }}>
        <div className="page-header-container">
          <PageHeader
            className="page-header"
            title={intl.formatMessage({ id: "users" })}
            ghost={false}
          />
        </div>
        <Form form={searchForm} layout="vertical">
          <Row gutter={16}>
            <Col>
              <Form.Item label={intl.formatMessage({ id: "username" })} name="username">
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={intl.formatMessage({ id: "officialName" })} name="officialName">
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={intl.formatMessage({ id: "email" })} name="email">
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={intl.formatMessage({ id: "phone" })} name="phone">
                <Input />
              </Form.Item>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <Form.Item label=" ">
                <Button onClick={submitSearch} type="primary" className="mr-8">
                  {intl.formatMessage({ id: "query" })}
                </Button>
                <Button onClick={resetSearch} className="mr-8">
                  {intl.formatMessage({ id: "reset" })}
                </Button>
                <Button type="primary" className="mr-8" onClick={() => {
                    setUserModalVisible(true);
                    setSelectedUserContacts([]);
                  }}>
                  {intl.formatMessage({ id: "new" })}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Table
          onChange={handleTableChange}
          bordered={true}
          columns={columns}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          rowKey="_id"
          scroll={{ x: 1300 }}
          size="small"
        />
      </div>
    </div>
  );
};

export default UsersPage;
