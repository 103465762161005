import moment from "moment";
import _ from "lodash";
import translate from "../../../helpers/translate";
import  { hotelsCRMURI } from "../../../config";

const formatDate = (data) => data.value && moment(data.value).format("LL");
const multiObjNameRenderer = ({ value, defaultValue }) =>
  _.isArray(value) && !_.isEmpty(value) ? value.map((i) => i).join(", ") : defaultValue || "";

export default translate({
  archivePage: {
    columnDefs: [
      {
        headerName: "actions",
        field: "actions",
        pinned: "left",
        hide: false,
        cellRenderer: "actionsRenderer",
        cellRendererParams: (params) => {
          const { _hotel, _id, contractId, recipientId } = params.data;
          return {
            seenWarning: true,
            link: `${hotelsCRMURI}${_hotel.slug}/restriction/${_id}/${contractId}/${recipientId}?portal=true`,
          };
        },
        width: 100,
        resizable: false,
        suppressSizeToFit: true,
        filter: false,
        sortable: false,
        floatingFilter: false,
      },
      {
        headerName: "hotel",
        field: "_hotel.name",
        pinned: "left",
      },
      {
        headerName: "contractId",
        field: "contractId",
        pinned: "left",
      },
      {
        headerName: "createdOn",
        field: "_createdOn",
        width: 80,
        cellRenderer: formatDate,
      },
      {
        headerName: "restrictionType",
        field: "restrictionType",
      },
      {
        headerName: "status",
        field: "status",
        cellRenderer: "statusRenderer",
      },
      {
        headerName: "from",
        field: "from",
        width: 80,
        cellRenderer: formatDate,
      },
      {
        headerName: "to",
        field: "to",
        width: 80,
        cellRenderer: formatDate,
      },
      {
        headerName: "sent",
        field: "sent",
        width: 120,
        cellRenderer: "sentRenderer",
      },
      {
        headerName: "seen",
        field: "seen",
        width: 120,
        cellRenderer: "seenRenderer",
      },
      {
        headerName: "notes",
        field: "notes",
      },
      {
        headerName: "rateSegment",
        field: "rateSegment",
        cellRenderer: multiObjNameRenderer,
      },
      {
        headerName: "season",
        field: "season",
        cellRenderer: multiObjNameRenderer,
      },
      {
        headerName: "market",
        field: "market",
        cellRenderer: multiObjNameRenderer,
      },
    ],
    defaultColDefs: {
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
    },
  },
});
