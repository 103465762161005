import React from "react";
import { useIntl } from "react-intl";
import _ from "lodash";

import { Collapse, List, Timeline, Space } from "antd";
import { moment } from "../../../helpers/moment";
import { currencies } from "../../../constants";

const { Panel } = Collapse;

const PriceAnalysis = ({ data, activeKey, setActiveKey, showArrow = true, hotel }) => {
  const intl = useIntl();

  const currency = currencies.find((c) => c.value === hotel.currency);
  const currencySymbol = _.get(currency, "symbol", "");

  return (
    <Collapse activeKey={activeKey} onChange={setActiveKey}>
      {data.priceAnalysis.map((pa, index) => (
        <Panel
          showArrow={showArrow}
          header={moment(pa.date).format("DD/MM/YYYY")}
          extra={[
            `${intl.formatMessage({ id: "dayTotalPrice" })}: ${currencySymbol}${pa.amount.toFixed(2)}`,
          ]}
          key={index}
        >
          <List
            dataSource={pa.pricePerPax}
            renderItem={(item) => (
              <List.Item>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 style={{ textTransform: "capitalize" }}>
                      {item.paxType === "child"
                        ? `${item.paxType} (${intl.formatMessage({ id: "age" })}: ${item.age})`
                        : item.paxType}
                    </h4>
                    <h4>
                      {`${intl.formatMessage({
                        id: "paxTotalPrice",
                      })}: ${currencySymbol}${item.amount.toFixed(2)}`}
                    </h4>
                  </div>
                  <Timeline>
                    {item.priceBreakdown.map((pb, i) => (
                      <Timeline.Item
                        key={i}
                        style={{ paddingBottom: i === item.priceBreakdown.length - 1 ? 0 : 10 }}
                      >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <span>{pb.description}</span>
                          <span>
                            {`${intl.formatMessage({ id: "price" })}: ${currencySymbol}${pb.rate.toFixed(2)}`}
                          </span>
                        </div>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </Space>
              </List.Item>
            )}
          ></List>
        </Panel>
      ))}
    </Collapse>
  );
};

export default PriceAnalysis;
