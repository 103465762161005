import { forwardRef, useImperativeHandle, useState } from "react";
import { Upload, Modal } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import _ from "lodash";

const { Dragger } = Upload;

export default forwardRef((props, ref) => {
  const token = useSelector((state) => state.auth.token);
  const selectedHotel = useSelector((state) => state.options.selectedHotel);
  const [fileList, setFileList] = useState(props.value || []);
  const [visible, setVisible] = useState(true);

  useImperativeHandle(ref, () => {
    return {
      getValue: () =>
        fileList.map((file) => {
          file.mimetype = file.type || file.mimetype;
          return _.isEmpty(file.response)
            ? _.pick(file, ["uid", "name", "url", "mimetype"])
            : file.response;
        }),
    };
  });

  const uploadProps = {
    action: "/api/v1/uploads",
    headers: {
      Authorization: `Bearer ${token}`,
      tenant: selectedHotel,
    },
    fileList,
    multiple: true,
    showUploadList: {
      showDownloadIcon: true,
      showRemoveIcon: true,
    },
    onChange: ({ fileList }) => setFileList(fileList),
  };

  const onFinish = () => {
    setVisible(false);
    props.stopEditing();
  };

  return (
    <div>
      <Modal
        title="Upload Files"
        visible={visible}
        onOk={onFinish}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from uploading company data or
            other band files
          </p>
        </Dragger>
      </Modal>
    </div>
  );
});
