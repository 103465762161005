import { Component } from "react";
import { Tooltip } from "antd";
import {
  FileExcelOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  FileImageOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileZipOutlined,
  FileOutlined,
} from "@ant-design/icons";
import _ from "lodash";

export default class FilesRenderer extends Component {
  state = {
    value: this.props.value,
  };

  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({ value: params.value });
    }
    return true;
  }

  render() {
    const { value } = this.state;
    const fontSize = this.props.size || 16;
    if (_.isEmpty(value)) return null;
    const renderIcon = (filename) => {
      const showFileName = this.props.showFileName ? filename : null;
      const fileExt = filename.split(".").pop();
      let icon;
      switch (fileExt) {
        case "png":
        case "gif":
        case "jpeg":
        case "jpg":
        case "tiff":
          icon = <FileImageOutlined style={{ fontSize }} />;
          break;
        case "pdf":
          icon = <FilePdfOutlined style={{ fontSize }} />;
          break;
        case "txt":
          icon = <FileTextOutlined style={{ fontSize }} />;
          break;
        case "zip":
        case "rar":
          icon = <FileZipOutlined style={{ fontSize }} />;
          break;
        case "ppt":
        case "pptx":
          icon = <FilePptOutlined style={{ fontSize }} />;
          break;
        case "doc":
        case "docx":
          icon = <FileWordOutlined style={{ fontSize }} />;
          break;
        case "xls":
        case "xlsx":
        case "csv":
          icon = <FileExcelOutlined style={{ fontSize }} />;
          break;
        default:
          icon = <FileOutlined style={{ fontSize }} />;
          break;
      }
      return (
        <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {icon} {showFileName}
        </span>
      );
    };
    const files = value.map((v) => (
      <a href={v.url} key={v.uid} download target="_blank" rel="noreferrer">
        <Tooltip title={v.name}>{renderIcon(v.name)}</Tooltip>
      </a>
    ));
    return <div style={{ display: "flex" }}>{files}</div>;
  }
}
