import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Tag } from "antd";
import {
  cyan,
  green,
  grey,
  lime,
  orange,
  red,
  volcano,
} from "@ant-design/colors";
import { SyncOutlined } from "@ant-design/icons";
export default class StatusRenderer extends Component {
  state = {
    value: this.props.value,
  };

  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({ value: params.value });
    }
    return true;
  }

  renderValue() {
    const { value } = this.state;
    const translatedValue = <FormattedMessage id={value} />;
    switch (value) {
      case "Active":
      case "active":
      case "Confirmed":
      case "resolved":
        return <Tag color={green.primary}>{translatedValue}</Tag>;
      case "inactive":
      case "Payment Intent":
      case "unresolved":
        return <Tag color={orange.primary}>{translatedValue}</Tag>;
      case "blocked":
      case "Cancelled":
      case "Rejected":
      case "Out Of Availability":
        return <Tag color={red.primary}>{translatedValue}</Tag>;
      case "Provisionally Confirmed":
      case "ignored":
        return <Tag color={cyan.primary}>{translatedValue}</Tag>;
      case "expired":
      case "Expired":
        return <Tag color={volcano.primary}>{translatedValue}</Tag>;
      case "Pending":
      case "Draft":
      case "draft":
        return <Tag color={grey[1]}>{translatedValue}</Tag>;
      case "Realized":
        return <Tag color={lime.primary}>{translatedValue}</Tag>;
      case "inReview":
        return (
          <Tag icon={<SyncOutlined spin />} color="processing">
            {translatedValue}
          </Tag>
        );
      default:
        return value;
    }
  }

  render() {
    return <div>{this.renderValue()}</div>;
  }
}
