import React, { useState } from "react";
import { useIntl } from "react-intl";
import _ from "lodash";

import { Button, Modal } from "antd";
import { moment } from "../../../helpers/moment";
import { currencies } from "../../../constants";
import PriceAnalysis from "./PriceAnalysis";

const PriceAnalysisModal = ({ data, isModalVisible, onCancel, hotel }) => {
  const intl = useIntl();
 
  const [activeKey, setActiveKey] = useState(null);

  const currency = currencies.find((c) => c.value === hotel.currency);
  const currencySymbol = _.get(currency, "symbol", "");

  const handleOnCancel = () => {
    onCancel();
    setActiveKey(null);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: "priceAnalysis" })}
      visible={isModalVisible}
      onCancel={handleOnCancel}
      width={1000}
      footer={[
        <Button key="close" onClick={handleOnCancel}>
          {intl.formatMessage({ id: "close" })}
        </Button>,
      ]}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h3>
            {`${intl.formatMessage({ id: "dates" })}: 
            ${moment(data.arrival).format("DD/MM/YYYY")} - 
            ${moment(data.departure).format("DD/MM/YYYY")}`}
          </h3>
          <h3>
            {`${intl.formatMessage({ id: "contract" })}: ${data.contract}`}
          </h3>
          {data.name &&
            <h3>
              {`${intl.formatMessage({ id: "offer" })}: ${data.name}`}
            </h3>
          }
        </div>
        <h3>
          {`${intl.formatMessage({ id: "totalAmount" })}: 
          ${currencySymbol}${data.amount.toFixed(2)}`}
        </h3>
      </div>
      <PriceAnalysis data={data} setActiveKey={setActiveKey} activeKey={activeKey} hotel={hotel}/>
    </Modal>
  );
};

export default PriceAnalysisModal;
