import moment from "moment";

import translate from "../../../helpers/translate";
import { hotelsCRMURI } from "../../../config";

const formatDate = (data) =>
  data.value && moment(data.value).format("DD/MM/YYYY HH:mm");

export default translate({
  archivePage: {
    columnDefs: [
      {
        headerName: "actions",
        field: "actions",
        pinned: "left",
        hide: false,
        cellRenderer: "actionsRenderer",
        cellRendererParams: (params) => {
          const { _hotel, _id, recipientId } = params.data;
          return {
            seenWarning: true,
            link: `${hotelsCRMURI}${_hotel.slug}/contract/${_id}/${recipientId}?portal=true`,
          };
        },
        width: 100,
        resizable: false,
        suppressSizeToFit: true,
        filter: false,
        sortable: false,
        floatingFilter: false,
      },
      {
        headerName: "hotel",
        field: "_hotel.name",
        pinned: "left",
      },
      {
        headerName: "cooperation",
        field: "cooperation",
        pinned: "left",
      },
      {
        headerName: "contractType",
        field: "contractType",
      },
      {
        headerName: "season",
        field: "season",
      },
      {
        headerName: "rateSegment",
        field: "rateSegment",
      },
      {
        headerName: "contractFiles",
        field: "contractFiles",
        cellRenderer: "filesRenderer",
        cellEditor: "filesEditor",
        editable: true,
      },
      {
        headerName: "status",
        field: "status",
        cellRenderer: "statusRenderer",
        cellStyle: {
          textAlign: "center",
        },
        width: 120,
      },
      {
        headerName: "sent",
        field: "sent",
        width: 120,
        cellRenderer: "sentRenderer",
      },
      {
        headerName: "seen",
        field: "seen",
        width: 120,
        cellRenderer: "seenRenderer",
      },
      {
        headerName: "market",
        field: "market",
      },
      {
        headerName: "boardTerm",
        field: "baseBoardTerm",
      },
      {
        headerName: "createdOn",
        field: "_createdOn",
        width: 120,
        cellRenderer: formatDate,
      },
      {
        headerName: "files",
        field: "files",
        cellRenderer: "filesRenderer",
      },
    ],
    defaultColDefs: {
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
    },
  },
});
