import { Component, createRef } from "react";
import { Select } from "antd";

const { Option } = Select;

export default class BooleanEditor extends Component {
  constructor(props) {
    super(props);
    this.item = createRef();
    this.onChange = this.onChange.bind(this);
    this.state = {
      value: props.value || false,
    };
  }

  getValue() {
    return this.state.value;
  }

  onChange(value) {
    this.setState({ value });
  }

  render() {
    const { value } = this.props;
    return (
      <Select
        defaultValue={value}
        mode="single"
        onChange={this.onChange}
        optionFilterProp="children"
        size="small"
        style={{ width: "100%" }}
      >
        <Option key="true" value={true}>
          Yes
        </Option>
        <Option key="false" value={false}>
          No
        </Option>
      </Select>
    );
  }
}
