import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import _ from "lodash";

import { PageHeader, Form, Tabs } from "antd";

import { moment } from "../../helpers/moment";
import { restrictionsCalendarFetch, restrictionsSearch } from "../../redux/actions";
import tableColumns from "./constants/tableColumns";
import ArchiveTable from "../../components/table/ArchiveTable";
import Loader from "./partials/Loader";
import CalendarLoader from "./partials/CalendarLoader";
import RestrictionsCalendar from "./partials/RestrictionsCalendar";

const { TabPane } = Tabs;

const RestrictionsArchivePage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [rowData, setRowData] = useState([]);
  const [dailyData, setDailyData] = useState([]);
  const [contracts, setContracts] = useState();

  //  Loader Form
  const [loaderForm] = Form.useForm();
  const [calendarLoaderForm] = Form.useForm();

  const { columnDefs, defaultColDefs } = tableColumns(intl).archivePage;
  const gridApi = useRef(null);
  const columnApi = useRef(null);

  const formatData = (data) => {
    if (_.isEmpty(data)) return [];
    const restrictions = [];
    const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    data.forEach((d) => {
      const fromDate = moment(d.from).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const toDate = moment(d.to).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const range = moment.range([fromDate, toDate]);
      let status;

      if (range.contains(today) || toDate.diff(today) >= 0) {
        status = "active";
      } else {
        status = "Expired";
      }

      d.contracts.forEach((contract) =>
        restrictions.push({
          ...d,
          _id: d._id,
          status,
          contractId: contract,
        })
      );
    });
    return restrictions;
  };

  const fetchData = () => {
    const filters = loaderForm.getFieldsValue();
    gridApi.current.showLoadingOverlay();
    return dispatch(restrictionsSearch({ filters })).then(({ payload }) => {
      setRowData(payload);
      gridApi.current.paginationGoToFirstPage();
      gridApi.current.hideOverlay();
    });
  };

  const fetchCalendarData = () => {
    const filters = calendarLoaderForm.getFieldsValue();
    dispatch(restrictionsCalendarFetch({ filters })).then(({ payload }) => setDailyData(payload));
  };

  const onGridReady = (params) => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;
    fetchData();
  };

  useEffect(() => {
    loaderForm.setFieldsValue({ fromTo: [moment(), moment().add(2, "years")] });
    calendarLoaderForm.setFieldsValue({ fromTo: [moment(), moment().add(2, "years")] });
  }, []);

  return (
    <>
      <div className="page-header-container">
        <PageHeader
          className="page-header"
          title={intl.formatMessage({ id: "restrictions" })}
          ghost={false}
        />
      </div>
      <div>
        <Tabs defaultActiveKey="Table" tabPosition="top" animated={false} type="card">
          <TabPane tab={intl.formatMessage({ id: "table" })} key="Table">
            <div>
              <Loader form={loaderForm} loading={false} loadData={fetchData} />
            </div>
            <div style={{ width: "100%" }}>
              <ArchiveTable
                columnDefs={columnDefs}
                defaultColDefs={defaultColDefs}
                onGridReady={onGridReady}
                getRowNodeId={(data) => `${data._id}-${data.restrictionId}`}
                rowData={formatData(rowData)}
              />
            </div>
          </TabPane>
          <TabPane tab={intl.formatMessage({ id: "calendar" })} key="Calendar">
            <div>
              <CalendarLoader
                form={calendarLoaderForm}
                loading={false}
                loadData={fetchCalendarData}
                contracts={contracts}
                setContracts={setContracts}
              />
            </div>
            <RestrictionsCalendar
              events={dailyData}
              contract={contracts?.find(
                (contract) => contract._id === calendarLoaderForm.getFieldValue("contract")
              )}
            />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default RestrictionsArchivePage;
