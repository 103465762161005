import React from "react";
import { notification } from "antd";
import { useIntl } from "react-intl";
import _ from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";

const PublicURLRenderer = (params) => {
  const intl = useIntl();
  if (_.isEmpty(params.data)) return "Empty Data";
  const renderParams = _.get(params, "data.renderParams", {});
  const { data } = params;
  let url;
  switch (renderParams?.docType) {
    case "Contract":
      url = `https://${window.location.hostname}/public/${_.get(
        renderParams,
        "slug"
      )}/contract/${_.get(renderParams, "_id")}/${_.get(data, "_id")}`;
      break;
    case "Event":
      url = `https://${window.location.hostname}/public/${_.get(
        renderParams,
        "slug"
      )}/event/${_.get(renderParams, "_id")}/${_.get(data, "_id")}`;
      break;
    case "Group":
      url = `https://${window.location.hostname}/public/${_.get(
        renderParams,
        "slug"
      )}/group/${_.get(renderParams, "_id")}/${_.get(data, "_id")}`;
      break;
    case "Offer":
      url = `https://${window.location.hostname}/public/${_.get(
        renderParams,
        "slug"
      )}/offer/${_.get(renderParams, "_id")}/${_.get(data, "contractId")}/${_.get(data, "_id")}`;
      break;
    case "Restriction":
      url = `https://${window.location.hostname}/public/${_.get(
        renderParams,
        "slug"
      )}/restriction/${_.get(renderParams, "_id")}/${_.get(data, "contractId")}/${_.get(
        data,
        "_id"
      )}`;
      break;
    default:
      url = "-";
      break;
  }
  return (
    <CopyToClipboard
      text={url}
      onCopy={() =>
        notification.success({ message: intl.formatMessage({ id: "urlCopiedToClipboard" }) })
      }
      style={{ cursor: "pointer" }}
    >
      <span>{url}</span>
    </CopyToClipboard>
  );
};

export default PublicURLRenderer;
