import { useIntl } from "react-intl";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { moment } from "../../../helpers/moment";

import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { contractsSearch } from "../../../redux/actions";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Loader = ({ form, loading, loadData, contracts, setContracts }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [selectedHotel, setSelectedHotel] = useState();

  const hotels = useSelector((state) => state.hotelsCRM.hotels, shallowEqual);

  const requiredRule = { required: true, message: intl.formatMessage({ id: "fieldRequired" }) };

  const fetchContracts = () => {
    if (selectedHotel) {
      dispatch(contractsSearch({ filters: { _hotel: selectedHotel } })).then(({ payload }) =>
        setContracts(payload)
      );
    }
  };

  useEffect(() => {
    setContracts(null);
    fetchContracts();
  }, [selectedHotel]);

  return (
    <Form layout="vertical" name="restrictions-loader" form={form} onFinish={loadData}>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item
            name="_hotel"
            label={intl.formatMessage({ id: "hotel" })}
            rules={[requiredRule]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder={intl.formatMessage({ id: "selectHotel" })}
              allowClear
              value={selectedHotel}
              onChange={(val) => {
                setSelectedHotel(val);
                form.setFieldsValue({ contract: null });
              }}
            >
              {hotels.map((hotel) => (
                <Option key={hotel._id} value={hotel._id}>
                  {hotel.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item
            name="contract"
            label={intl.formatMessage({ id: "contract" })}
            rules={[requiredRule]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder={intl.formatMessage({ id: "selectContract" })}
              allowClear
              disabled={!selectedHotel}
            >
              {contracts?.map((contract) => (
                <Option key={contract._id} value={contract._id}>
                  {`${contract.rateSegment} - ${contract.season} - ${contract.market} (${contract.contractType})`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item
            name="fromTo"
            label={intl.formatMessage({ id: "from/to" })}
            rules={[requiredRule]}
          >
            <RangePicker
              allowClear
              placeholder={[intl.formatMessage({ id: "from" }), intl.formatMessage({ id: "to" })]}
              style={{ width: "100%" }}
              format="DD/MM/YYYY"
              defaultValue={[moment(), moment().add(2, "years")]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={4} xxl={4}>
          <Form.Item label={intl.formatMessage({ id: "actions" })}>
            <Button disabled={loading} type="primary" htmlType="submit" style={{ marginRight: 8 }}>
              {intl.formatMessage({ id: "load" })}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Loader;
