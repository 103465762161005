import { Component } from "react";
import { DatePicker } from "antd";
import _ from "lodash";
import moment from "moment";

const { RangePicker } = DatePicker;

export default class DateRangeEditor extends Component {
  state = {
    value: this.props.value
      ? _(this.props.value)
          .at(["from", "to"])
          .map((d) => moment(d))
          .value()
      : null,
  };

  getValue() {
    if (!this.state.value || !this.state.value.length) return {};
    return {
      from: this.state.value[0].utc().format(),
      to: this.state.value[1].utc().format(),
    };
  }

  onChange = (value) => this.setState({ value });

  render() {
    return (
      //  TODO: Add Locale
      <RangePicker
        size="small"
        onChange={this.onChange}
        value={this.state.value}
        style={{ width: "100%" }}
        format={moment.localeData().longDateFormat("L")}
      />
    );
  }
}
