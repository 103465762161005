import * as types from "./types";

export const hotelsCRMLogin = () => {
  return (api) => ({
    type: types.HOTELSCRM_LOGIN,
    payload: api.post("v1/hotelscrm/login"),
  });
};

export const contractsSearch = ({ filters }) => {
  return (api) => ({
    type: types.CONTRACTS_SEARCH,
    payload: api.post("v1/hotelscrm/contracts", filters),
  });
};

export const hotelsSearch = ({ filters }) => {
  return (api) => ({
    type: types.HOTELS_SEARCH,
    payload: api.post("v1/hotelscrm/hotels", filters),
  });
};

export const offersSearch = ({ filters }) => {
  return (api) => ({
    type: types.OFFERS_SEARCH,
    payload: api.post("v1/hotelscrm/offers", filters),
  });
};

export const restrictionsSearch = ({ filters }) => {
  return (api) => ({
    type: types.RESTRICTIONS_SEARCH,
    payload: api.post("v1/hotelscrm/restrictions", filters),
  });
};


export const restrictionsCalendarFetch = ({ filters }) => {
  return (api) => ({
    type: types.RESTRICTIONS_CALENDAR_FETCH,
    payload: api.post("v1/hotelscrm/restrictions/calendar", filters),
  });
};

export const groupsSearch = ({ filters }) => {
  return (api) => ({
    type: types.GROUPS_SEARCH,
    payload: api.post("v1/hotelscrm/groups", filters),
  });
};

export const eventsSearch = ({ filters }) => {
  return (api) => ({
    type: types.EVENTS_SEARCH,
    payload: api.post("v1/hotelscrm/events", filters),
  });
};

export const reservationsSearch = (data) => {
  return (api) => ({
    type: types.RESERVATIONS_SEARCH,
    payload: api.post("v1/hotelscrm/reservations/search", data),
  });
};

export const reservationFetch = (id) => {
  return (api) => ({
    type: types.RESERVATION_FETCH,
    payload: api.get(`v1/hotelscrm/reservations/${id}`),
  });
};

export const reservationCreate = (data) => {
  return (api) => ({
    type: types.RESERVATION_CREATE,
    payload: api.post("v1/hotelscrm/reservations", data),
    success: {
      message: "success",
      description: "reservationCreated",
    },
  });
};

export const reservationUpdate = (data, options) => {
  return (api) => ({
    type: types.RESERVATION_UPDATE,
    payload: api.put("v1/hotelscrm/reservations", { data, options }),
    success: {
      message: "success",
      description: "reservationUpdated",
    },
  });
};

export const reservationsGetPrice = (data) => {
  return (api) => ({
    type: types.RESERVATIONS_GET_PRICE,
    payload: api.post("v1/hotelscrm/reservations/get-price", data),
  });
};