import { Component } from "react";
import { InputNumber } from "antd";
import _ from "lodash";

export default class NumericRangeEditor extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = { ...value };
  }

  getValue() {
    return this.state;
  }

  onChangeFrom = (from) => {
    const { min, max } = this.props;
    if (_.isInteger(min) && from < min) return this.setState({ from: min });
    if (_.isInteger(max) && from > max) return this.setState({ from: max });
    return this.setState({ from });
  };
  onChangeTo = (to) => {
    const { min, max } = this.props;
    if (_.isInteger(min) && to < min) return this.setState({ to: min });
    if (_.isInteger(max) && to > max) return this.setState({ to: max });
    return this.setState({ to });
  };

  render() {
    const { min, max } = this.props;
    const { from, to } = this.state;
    return (
      <div style={{ display: "flex" }}>
        <InputNumber
          size="small"
          placeholder="From"
          onChange={this.onChangeFrom}
          value={from}
          defaultValue={from}
          min={min}
          max={max}
        />
        <span>-</span>
        <InputNumber
          size="small"
          placeholder="To"
          onChange={this.onChangeTo}
          value={to}
          defaultValue={to}
          min={min}
          max={max}
        />
      </div>
    );
  }
}
