import { Link } from "react-router-dom";
import _ from "lodash";

const LinkRenderer = ({ link, data, value, idProp }) => {
  return idProp ? (
    <Link to={`${link}${_.get(data, idProp)}`}>{value}</Link>
  ) : (
    <Link to={`${link}${data._id}`}>{value}</Link>
  );
};

export default LinkRenderer;
