import React from "react";
import { useIntl } from "react-intl";
import { useSelector, shallowEqual } from "react-redux";

import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import { moment } from "../../../helpers/moment";
import { conditionOptions, reservationStatuses } from "../../../constants";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Loader = ({ form, loading, fetchData, exportCsv }) => {
  const intl = useIntl();

  const hotels = useSelector((state) => state.hotelsCRM.hotels, shallowEqual);

  return (
    <Form
      form={form}
      layout="vertical"
      name="channelManager-filter-form"
      onFinish={fetchData}
      initialValues={{
        condition: "creation",
        fromTo: [moment().startOf("day"), moment().add(1, "year")],
        status: null,
      }}
    >
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item name="_hotel" label={intl.formatMessage({ id: "hotel" })}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder={intl.formatMessage({ id: "allHotels" })}
              allowClear
            >
              {hotels.map((hotel) => (
                <Option key={hotel._id} value={hotel._id}>
                  {hotel.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item
            label={intl.formatMessage({ id: "period" })}
            name="fromTo"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "required" }),
              },
            ]}
          >
            <RangePicker
              style={{ width: "100%", zIndex: 4 }}
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6} lg={4} xl={4}>
          <Form.Item label={intl.formatMessage({ id: "condition" })} name="condition" rules={[{ required: true, message: intl.formatMessage({ id: "required" }) }]}>
            <Select>
              {conditionOptions.map(({ label, value }) => (
                <Option key={value} value={value}>
                  {intl.formatMessage({ id: label })}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={4} xl={4}>
          <Form.Item label={intl.formatMessage({ id: "status" })} name="status">
            <Select>
              <Option key={0} value={null}>
                {intl.formatMessage({ id: "all" })}
              </Option>
              {reservationStatuses.map(({ label, value }) => (
                <Select key={value} value={value}>
                  {intl.formatMessage({ id: label })}
                </Select>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label=" ">
            <Button disabled={loading} type="primary" htmlType="submit">
              {intl.formatMessage({ id: "load" })}
            </Button>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label=" ">
            <Button disabled={loading} type="default" onClick={exportCsv}>
              {intl.formatMessage({ id: "exportTable" })}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Loader;
