import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PageHeader } from "antd";
import { useDispatch } from "react-redux";

import { hotelsSearch } from "../../redux/actions";
import tableColumns from "./constants/tableColumns";
import ArchiveTable from "../../components/table/ArchiveTable";

const HotelsArchivePage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [rowData, setRowData] = useState([]);

  const { columnDefs, defaultColDefs } = tableColumns(intl).archivePage;
  const gridApi = useRef(null);
  const columnApi = useRef(null);

  const fetchData = () => {
    gridApi.current.showLoadingOverlay();
    return dispatch(hotelsSearch({})).then(({ payload }) => {
      setRowData(payload);
      gridApi.current.paginationGoToFirstPage();
      gridApi.current.hideOverlay();
    });
  };

  const onGridReady = (params) => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;
    fetchData();
  };

  return (
    <>
      <div className="page-header-container">
        <PageHeader
          className="page-header"
          title={intl.formatMessage({ id: "hotels" })}
          ghost={false}
        />
      </div>

      <div style={{ width: "100%" }}>
        <ArchiveTable
          columnDefs={columnDefs}
          defaultColDefs={defaultColDefs}
          onGridReady={onGridReady}
          getRowNodeId={(data) => data._id}
          rowData={rowData}
        />
      </div>
    </>
  );
};

export default HotelsArchivePage;
