import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Input, Result, Row, Spin } from "antd";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";

import { checkResetPasswordToken, resetPassword, sendResetPasswordLink } from "../redux/actions";

import logo from "../assets/images/logo.png";

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [globalError, setGlobalError] = useState("");
  const { token } = params;
  const [user, setUser] = useState();
  const [tokenError, setTokenError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (token) {
      setLoading(true);
      dispatch(checkResetPasswordToken(token))
        .then((res) => {
          if (res.payload) {
            setUser(res.payload);
          }
        })
        .catch((err) => setTokenError(err.message))
        .finally(() => setLoading(false));
    }
  }, []);

  const handlePasswordForm = (values) => {
    const { password, repeatPassword } = values;
    if (!password || !repeatPassword) return setGlobalError("Both passwords are required");
    if (password !== repeatPassword) return setGlobalError("Passwords are not the same!");
    setSubmitting(true);
    dispatch(resetPassword(password, token))
      .then(() => setSuccessMessage("Your Password Has Been Reset!"))
      .catch((err) => setGlobalError(err.message))
      .finally(() => setSubmitting(false));
  };

  const handleEmailForm = (values) => {
    const { email } = values;
    if (!email) return setGlobalError("Email is required");
    setSubmitting(true);
    dispatch(sendResetPasswordLink(email))
      .then(() => setSuccessMessage("Reset Password link has been sent to your email"))
      .catch((err) => setGlobalError(err.message))
      .finally(() => setSubmitting(false));
  };

  if (loading)
    return (
      <Spin
        style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
        size="large"
      />
    );
  if (tokenError)
    return (
      <Result
        status="500"
        title={tokenError}
        extra={
          <Button type="primary" key="redirect" onClick={() => history.push("/login")}>
            Back to Login Page
          </Button>
        }
      />
    );

  return (
    <React.Fragment>
      <Row id="loginPageWrapper" type="flex" justify="center" align="middle">
        <Col>
          <Row type="flex" justify="center" align="middle">
            <img id="logo" src={logo} alt="HotelsCRM Logo" />
          </Row>
          <Card id="login-card" bordered={false}>
            <Row type="flex" justify="center" align="middle" style={{ marginBottom: 12 }}>
              <span id="loginText">Password Reset</span>
            </Row>
            {token && (
              <Row>
                <Col span={24} style={{ textAlign: "center", marginBottom: 24 }}>
                  Welcome back {_.get(user, "officialName")}, enter your new password below:
                </Col>
                {globalError && (
                  <Col span={24}>
                    <Alert
                      id="loginError"
                      message={globalError}
                      type="error"
                      showIcon
                      style={{ marginBottom: "10px" }}
                    />
                  </Col>
                )}
                {successMessage && (
                  <Col span={24}>
                    <Alert
                      id="successMessage"
                      message={successMessage}
                      type="success"
                      showIcon
                      style={{ marginBottom: "10px" }}
                    />
                  </Col>
                )}
                <Col span={24}>
                  <Form onFinish={handlePasswordForm} className="login-form" layout="vertical">
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[{ required: true, message: "Please input your Password!" }]}
                    >
                      <Input.Password style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                      label="Repeat Password"
                      name="repeatPassword"
                      rules={[{ required: true, message: "Please repeat your Password!" }]}
                    >
                      <Input.Password style={{ width: "100%" }} />
                    </Form.Item>
                    <Row>
                      <Col span={24}>
                        {!successMessage && (
                          <Button
                            block
                            id="loginButton"
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            disabled={submitting}
                          >
                            Reset Password
                          </Button>
                        )}
                        <Button
                          block
                          type="default"
                          key="redirectLogin"
                          style={{ marginTop: 12 }}
                          onClick={() => history.push("/login")}
                        >
                          Back to Login
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            )}
            {!token && (
              <Row>
                <Col span={24} style={{ textAlign: "center", marginBottom: 24 }}>
                  Please enter your email below:
                </Col>
                {globalError && (
                  <Col span={24}>
                    <Alert
                      id="loginError"
                      message={globalError}
                      type="error"
                      showIcon
                      style={{ marginBottom: "10px" }}
                    />
                  </Col>
                )}
                {successMessage && (
                  <Col span={24}>
                    <Alert
                      id="successMessage"
                      message={successMessage}
                      type="success"
                      showIcon
                      style={{ marginBottom: "10px" }}
                    />
                  </Col>
                )}
                <Col span={24}>
                  <Form onFinish={handleEmailForm} className="login-form" layout="vertical">
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: "Please input your email" }]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                    <Row>
                      <Col span={24}>
                        {!successMessage && (
                          <Button
                            block
                            id="loginButton"
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            disabled={submitting}
                          >
                            Send me reset password link
                          </Button>
                        )}
                        <Button
                          block
                          type="default"
                          key="redirectLogin"
                          style={{ marginTop: 12 }}
                          onClick={() => history.push("/login")}
                        >
                          Back to Login
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
