import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import _ from "lodash";

import { Button, Form, PageHeader } from "antd";

import ArchiveTable from "../../components/table/ArchiveTable";
import Loader from "./partials/Loader";
import tableColumns from "./constants/tableColumns";
import { reservationsSearch } from "../../redux/actions";


const ReservationsArchivePage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const gridApi = useRef(null);
  const columnApi = useRef(null);

  //  Table columns and table api
  const { columnDefs, defaultColDefs } = tableColumns(intl).reservationsArchivePage;

  const fetchData = async () => {
    try {
      await form.validateFields();
      const filters = _.omitBy(form.getFieldsValue(), _.isNil);
      gridApi.current.showLoadingOverlay();
      if (!_.isEmpty(filters.fromTo)) {
        filters.from = filters.fromTo[0];
        filters.to = filters.fromTo[1];
        delete filters.fromTo;
      }
      setLoading(true);
      dispatch(reservationsSearch({ filters }))
        .then((res) => setData(_.get(res.payload, "docs", [])))
        .finally(() => {
          gridApi.current.paginationGoToFirstPage();
          gridApi.current.hideOverlay();
          setLoading(false);
        });
    } catch (err) {
      console.error(err.message);
    }
  };

  const onGridReady = (params) => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;
    fetchData();
  };

  return (
    <>
      <div className="page-header-container">
        <PageHeader
          className="page-header"
          title={intl.formatMessage({ id: "reservations" })}
          ghost={false}
          extra={
            <Button type="primary" onClick={() => history.push("/reservations/create")}>
              {intl.formatMessage({ id: "createReservation" })}
            </Button>
          }
        />
      </div>
      <div className="channelManager-filter-form">
        <Loader
          exportCsv={() => gridApi.current.exportDataAsCsv()}
          fetchData={fetchData}
          form={form}
          loading={loading}
        />
      </div>
      <div>
        <ArchiveTable
          columnDefs={columnDefs}
          defaultColDefs={defaultColDefs}
          getRowNodeId={(row) => row._id}
          rowSelection= "single"
          onGridReady={onGridReady}
          rowData={data}
        />
      </div>
    </>
  );
};

export default ReservationsArchivePage;
