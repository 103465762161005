import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { Button } from "antd";
import { hotelsCRMLogin } from "../../redux/actions";

const ForceReconnectToHotelsCRM = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const onClickReconnect = () => {
    dispatch(hotelsCRMLogin());
    history.push("/");
  };

  return (
    <Button key="forceReconnect"  onClick={onClickReconnect}>
      {intl.formatMessage({ id: "forceReconnectToHotelsCRM" })}
    </Button>
  );
};

export default ForceReconnectToHotelsCRM;
