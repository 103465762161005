import translate from "../../../helpers/translate";
import  { hotelsCRMURI } from "../../../config";

export default translate({
  archivePage: {
    columnDefs: [
      {
        headerName: "actions",
        field: "actions",
        pinned: "left",
        hide: false,
        cellRenderer: "actionsRenderer",
        cellRendererParams: (params) => {
          const { slug, _id } = params.data;
          return {
            link: `${hotelsCRMURI}${slug}/hotel/${_id}?portal=true`,
          };
        },
        width: 100,
        resizable: false,
        suppressSizeToFit: true,
        filter: false,
        sortable: false,
        floatingFilter: false,
      },
      {
        pinned: "left",
        headerName: "hotel",
        field: "name",
        sort: 'asc'
      },
      {
        headerName: "rating",
        field: "rating",
      },
      {
        headerName: "category",
        field: "category",
      },
      {
        headerName: "email",
        field: "email",
      },
      {
        headerName: "reservationsEmail",
        field: "reservationsEmail",
      },
      {
        headerName: "contractingEmail",
        field: "contractingEmail",
      },
      {
        headerName: "accountsEmail",
        field: "accountsEmail",
      },
      {
        headerName: "phone",
        field: "phone",
      },
    ],
    defaultColDefs: {
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
    },
  },
});
