import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";

import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";

import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  PlusOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import { contactTitles, countries } from "../../../constants";
import EditRoomModal from "./EditRoomModal";
import { roundTo2DecimalPlaces } from "../../../helpers";
import PriceAnalysisModal from "./PriceAnalysisModal";
import { hotelsSearch } from "../../../redux/actions";

const { Option } = Select;

const EditReservation = ({ form, id, rooms, setRooms }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  //  Local State variables
  const [selectedHotel, setSelectedHotel] = useState();
  const [isEditRoomModalVisible, setIsEditRoomModalVisible] = useState(false);
  const [isHotelsLoading, setIsHotelsLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [priceAnalysisModalVisible, setPriceAnalysisModalVisible] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState();

  //  Redux State variables
  const reservation = useSelector((state) => id ? state.hotelsCRM?.reservations?.data[id] : [], shallowEqual);
  const hotels = useSelector((state) => state.hotelsCRM.hotels, shallowEqual);

  //  Forms
  const [editRoomForm] = Form.useForm();

  //  Other variables
  const requiredRule = { required: true, message: intl.formatMessage({ id: "fieldRequired" }) };
  
  //  Table Columns
  const columns = [
    {
      title: intl.formatMessage({ id: "occupancyRoom" }),
      key: "occupancyRoom",
      dataIndex: "occupancyRoom",
      render: (text, record) => {
        const occupancyRoom = selectedHotel.rooms.occupancyRooms.find((i) => i._id === text);
        return `x${record.numOfRooms} ${occupancyRoom?.name}`;
      },
    },
    {
      title: intl.formatMessage({ id: "arrival" }),
      key: "arrival",
      dataIndex: "arrival",
      render: (text) => (!text ? null : moment(text).format("L")),
    },
    {
      title: intl.formatMessage({ id: "departure" }),
      key: "departure",
      dataIndex: "departure",
      render: (text) => (!text ? null : moment(text).format("L")),
    },
    {
      title: intl.formatMessage({ id: "boardTerm" }),
      key: "boardTerm",
      dataIndex: "boardTerm",
      render: (text) => {
        const boardTerm = selectedHotel.boardTerms.find((i) => i._id === text);
        return boardTerm?.name;
      },
    },
    {
      title: intl.formatMessage({ id: "pax" }),
      key: "pax",
      render: (value, record) => {
        return `${record.adults || 0} + ${record.children.length || 0} + ${record.infants || 0}`;
      },
    },
    {
      title: intl.formatMessage({ id: "offers" }),
      key: "name",
      dataIndex: "name",
      render: (text, record) => (
        <Tooltip title={record.description}>
          <span
            style={{
              cursor: record.description ? "pointer" : "default",
              textDecoration: record.description ? "underline" : "none",
            }}
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: intl.formatMessage({ id: "contract" }),
      key: "contract",
      dataIndex: "contract",
    },
    {
      title: intl.formatMessage({ id: "amount" }),
      key: "amount",
      dataIndex: "amount",
      fixed: "right",
      render: (num) => `€${roundTo2DecimalPlaces(num).toFixed(2)}`,
    },
    {
      title: intl.formatMessage({ id: "actions" }),
      key: "actions",
      fixed: "right",
      render: (record) => (
        <div style={{ display: "flex" }}>
          <Tooltip
            title={intl.formatMessage({ id: "editRoom" })}
            onClick={() => handleOnEditRoom(record)}
          >
            <Button type="link" icon={<EditOutlined />} />
          </Tooltip>
          <Tooltip
            title={
              <div>
                <div>
                  {record.warnings.length} {intl.formatMessage({ id: "warnings" })}
                </div>
                {record.warnings.map((warning) => (
                  <div key={warning}>{warning}</div>
                ))}
              </div>
            }
          >
            <Button
              type="link"
              icon={<WarningOutlined style={{ color: record.warnings.length ? "orange" : null }} />}
              disabled={!record.warnings.length}
            />
          </Tooltip>
          <Tooltip
            title={`${intl.formatMessage({ id: "remarks" })}: ${
              record.remarks || intl.formatMessage({ id: "none" })
            }`}
          >
            <Button type="link" icon={<MessageOutlined />} disabled={!record.remarks} />
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({ id: "priceAnalysis" })}
            onClick={() => {
              setSelectedRoom(record);
              setPriceAnalysisModalVisible(true);
            }}
          >
            <Button type="link" icon={<InfoCircleOutlined />} />
          </Tooltip>
          <Popconfirm
            title={intl.formatMessage({ id: "areYouSureYouWantToDeleteThisRow?" })}
            okText={intl.formatMessage({ id: "yes" })}
            cancelText={intl.formatMessage({ id: "no" })}
            onConfirm={() => handleOnDeleteRoom(record._id)}
          >
            <Tooltip title={intl.formatMessage({ id: "delete" })}>
              <Button type="link" icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleOnChangeHotel = (hotelId) => {
    form.resetFields();
    setRooms([]);
    form.setFieldsValue({ hotel: hotelId });
    const hotel = hotels.find(h => h._id === hotelId);
    setSelectedHotel(hotel);
  };

  const handleOnAddNewRoom = async () => {
    await editRoomForm.resetFields();
    await editRoomForm.setFieldsValue({ adults: 2, infants: 0, numOfRooms: 1 });
    setIsEditRoomModalVisible(true);
  };

  const handleOnEditRoom = async (record) => {
    setSelectedRoom(record);
    const editedRecord = {
      ...record,
      fromTo: [moment(record.arrival), moment(record.departure)],
    };
    await editRoomForm.setFieldsValue(editedRecord);
    setIsEditRoomModalVisible(true);
  };

  const handleOnDeleteRoom = (id) => setRooms(rooms.filter((r) => r._id !== id));

  const onAddRoom = (room) => {
    // Remove empty object guests because the fields are not required
    room.guests = _.reject(room.guests, _.isEmpty);
    setIsEditRoomModalVisible(false);
    const index = rooms.indexOf(selectedRoom);
    if (index !== -1) {
      const updatedRooms = rooms;
      updatedRooms[index] = room;
      setRooms([...updatedRooms]);
    } else {
      setRooms([...rooms, room]);
    }
  };

  useEffect(() => {
    setIsHotelsLoading(true);
    dispatch(hotelsSearch({})).finally(() => setIsHotelsLoading(false));
  }, []);

  //  If Reservation exists, set selected cooperation and contract
  useEffect(() => {
    if (!_.isEmpty(reservation)) {
      form.setFieldsValue({
        ..._.omit(reservation, "rooms"),
        cooperation: reservation.cooperation._id,
      });
      setRooms(reservation.rooms);
    }
  }, [reservation]);

  useEffect(() => {
    setTotalAmount(roundTo2DecimalPlaces(_.sumBy(rooms, "amount")));
  }, [rooms]);

  return (
    <div id="editReservation" style={{ display: "flex", flexDirection: "column" }}>
      <Form form={form} layout="vertical">
        <div style={{ backgroundColor: "#f5f5f5", padding: 16, marginBottom: 16 }}>
          <h4>{intl.formatMessage({ id: "hotelSelection" })}</h4>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={6} lg={6}>
              <Form.Item
                name="hotel"
                label={intl.formatMessage({ id: "hotel" })}
                rules={[requiredRule]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={handleOnChangeHotel}
                  loading={isHotelsLoading}
                >
                  {hotels.filter((hotel) => hotel.reservationsEnabled).map((hotel) => (
                    <Option key={hotel._id} value={hotel._id}>
                      {hotel.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
        {selectedHotel && (
          <React.Fragment>
            {selectedRoom && (
              <PriceAnalysisModal
                hotel={selectedHotel}
                isModalVisible={priceAnalysisModalVisible}
                onCancel={() => {
                  setPriceAnalysisModalVisible(false);
                  setSelectedRoom(null);
                }}
                data={selectedRoom}
              />
            )}
            <div style={{ backgroundColor: "#f5f5f5", padding: 16, marginBottom: 16 }}>
              <h4>{intl.formatMessage({ id: "rooms" })}</h4>
              {_.isEmpty(rooms) && (
                <Empty
                  image="/icons/double-bed.png"
                  imageStyle={{
                    height: 64,
                    opacity: "0.1",
                  }}
                  description={<span>{intl.formatMessage({ id: "noRoomsHaveBeenAddedYet" })}</span>}
                />
              )}
              {!_.isEmpty(rooms) && (
                <Table
                  columns={columns}
                  dataSource={rooms}
                  rowKey="_id"
                  pagination={false}
                  expandable={{ showExpandColumn: false }}
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginTop: 16,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Form.Item label={intl.formatMessage({ id: "totalAmount" })}>
                    <InputNumber
                      prefix="€"
                      value={roundTo2DecimalPlaces(totalAmount)}
                      disabled
                      style={{ marginRight: 16 }}
                      precision={2}
                    />
                  </Form.Item>
                </div>
                <Button type="primary" onClick={handleOnAddNewRoom} icon={<PlusOutlined />}>
                  {intl.formatMessage({ id: "addRoom" })}
                </Button>
              </div>
            </div>
            <div style={{ backgroundColor: "#f5f5f5", padding: 16, marginBottom: 16 }}>
              <h4>{intl.formatMessage({ id: "other" })}</h4>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label={intl.formatMessage({ id: "remarks" })} name="remarks">
                    <Input.TextArea rows={2} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label={intl.formatMessage({ id: "reference" })} name={["metadata", "reference"]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </React.Fragment>
        )}
      </Form>
      {selectedHotel && (
        <EditRoomModal
          room={selectedRoom}
          hotel={selectedHotel}
          form={editRoomForm}
          isVisible={isEditRoomModalVisible}
          onOk={onAddRoom}
          onCancel={() => {
            setIsEditRoomModalVisible(false);
            setSelectedRoom(null);
          }}
        />
      )}
    </div>
  );
};

export default EditReservation;
