import { moment } from "./moment";

const roundTo2DecimalPlaces = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

const renderArrayOfObjectNames = (array, prop, separator) =>
  array.map((i) => i[prop]).join(separator);

const renderUserName = (user) => {
  return `${user.officialName}`;
};

const getCurrentAndFuture10YSeasons = (seasons) => {
  return seasons
    .filter((season) => {
      const from = moment(season.fromTo.from).startOf("day");
      const to = moment(season.fromTo.to).endOf("day");
      const currentDate = moment().startOf("day");
      // If season is not active -> filter out
      if (!season.active) return false;
      // If current date is between from-to -> select
      if (currentDate.isBetween(from, to)) return true;
      // if the season is same or after today until 10 years ahead -> select
      if (from.isSameOrAfter(currentDate) && to.isSameOrBefore(currentDate.add("10", "years"))) {
        return true;
      }
      return false;
    })
    .map((s) => s._id);
};

export {
  getCurrentAndFuture10YSeasons,
  renderArrayOfObjectNames,
  renderUserName,
  roundTo2DecimalPlaces,
};
