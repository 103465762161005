import { Component, createRef } from "react";
import { InputNumber } from "antd";

export default class DoubleEditor extends Component {
  constructor(props) {
    super(props);
    this.item = createRef();
    this.state = {
      value: props.value,
    };
  }

  getValue() {
    return this.state.value;
  }

  onChange = (value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.props;
    return (
      <InputNumber
        style={{ width: "100%" }}
        defaultValue={value}
        onChange={this.onChange}
        size="small"
        allowClear
      />
    );
  }
}
