import _ from "lodash";

import translate from "../../../helpers/translate";
const { cooperationEmailNotifications, contactRoles } = require("../../../constants");

const requiredFunction = (params) =>
  (!params.value || (_.isObject(params.value) && _.isEmpty(params.value))) &&
  params.value !== false;

export default translate({
  contacts: {
    columnDefs: [
      {
        headerName: "id",
        field: "_id",
        width: 80,
        hide: true,
      },
      {
        headerName: "title",
        field: "title",
        pinned: "left",
        cellEditor: "singleSelectEditor",
        cellRenderer: "singleSelectRenderer",
        cellEditorParams: {
          options: [
            { label: "Mr", value: "Mr" },
            { label: "Ms", value: "Ms" },
            { label: "Mrs", value: "Mrs" },
            { label: "Miss", value: "Miss" },
            { label: "All", value: "All" },
          ],
        },
        editable: true,
        cellClassRules: {
          "error-required": requiredFunction,
        },
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        rowDrag: true,
        width: 150,
      },
      {
        headerName: "firstName",
        field: "firstName",
        pinned: "left",
        editable: true,
        minWidth: 120,
        cellClassRules: {
          "error-required": requiredFunction,
        },
      },
      {
        headerName: "lastName",
        field: "lastName",
        pinned: "left",
        editable: true,
        minWidth: 120,
        cellClassRules: {
          "error-required": requiredFunction,
        },
      },
      {
        headerName: "jobTitle",
        field: "jobTitle",
        editable: true,
      },
      {
        headerName: "phone",
        field: "phone",
        editable: true,
      },
      {
        headerName: "mobile",
        field: "mobile",
        editable: true,
      },
      {
        headerName: "fax",
        field: "fax",
        editable: true,
      },
      {
        headerName: "email",
        field: "email",
        editable: true,
        cellClassRules: {
          "error-required": requiredFunction,
        },
      },
      {
        headerName: "emailNotifications",
        field: "emailNotifications",
        cellRenderer: "multiSelectRenderer",
        cellEditor: "multiSelectEditor",
        cellRendererParams: { options: cooperationEmailNotifications },
        cellEditorParams: { options: cooperationEmailNotifications },
        editable: true,
      },
      {
        headerName: "roles",
        field: "roles",
        cellRenderer: "multiSelectRenderer",
        cellEditor: "multiSelectEditor",
        cellRendererParams: { options: contactRoles },
        cellEditorParams: { options: contactRoles },
        editable: true,
        minWidth: 150,
      },
      {
        headerName: "authorizationurl",
        field: "authorizationurl",
        editable: true,
      },
      {
        headerName: "gdpr",
        field: "gdpr",
        cellRenderer: "booleanRenderer",
        cellEditor: "booleanEditor",
      },
    ],
    defaultColDefs: {
      resizable: true,
    },
  },
});
