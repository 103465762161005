import * as types from "./types";

export const login = (username, password) => {
  return (api) => ({
    type: types.LOGIN,
    payload: api.post("v1/public/login", { username, password }).then((res) => {
      window.localStorage.setItem("token", res.data.token);
      return res;
    }),
  });
};

export const initialiseToken = () => {
  const token = window.localStorage.getItem("token");
  return {
    type: types.SET_TOKEN,
    payload: token,
  };
};

export const fetchMe = () => {
  return (api) => ({
    type: types.FETCH_ME,
    payload: api.get("v1/user/me"),
  });
};

export const logout = () => {
  window.localStorage.removeItem("token");
  return {
    type: types.LOGOUT,
  };
};

export const reset = () => {
  return {
    type: types.RESET,
  };
};

export const checkResetPasswordToken = (token) => {
  return (api) => ({
    type: types.CHECK_RESET_PASSWORD_TOKEN,
    payload: api.get(`v1/public/reset-password/${token}`),
  });
};

export const resetPassword = (password, token) => {
  return (api) => ({
    type: types.RESET_PASSWORD,
    payload: api.post(`v1/public/reset-password/${token}`, { password }),
  });
};

export const sendResetPasswordLink = (email) => {
  return (api) => ({
    type: types.SEND_RESET_PASSWORD_LINK,
    payload: api.post(`v1/public/reset-password`, { email }),
  });
};
