import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import _ from "lodash";
import { moment } from "../../helpers/moment";

import { PageHeader, Form } from "antd";
import { cyan, green, grey, lime, red, volcano } from "@ant-design/colors";

import { eventsSearch } from "../../redux/actions";
import tableColumns from "./constants/tableColumns";
import ArchiveTable from "../../components/table/ArchiveTable";
import { eventStatuses } from "../../constants";
import Loader from "./partials/Loader";

const style = {
  color: "white",
  fontWeight: 700,
  marginRight: 4,
  width: "100%",
  padding: 16,
};

const EventsArchivePage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  //  Get Events from redux
  const events = useSelector((state) => state.hotelsCRM.events, shallowEqual);
  const [rowData, setRowData] = useState([]);
  const [counters, setCounters] = useState({});

  //  Loader Form
  const [loaderForm] = Form.useForm();

  //  Table columns and table api
  const { columnDefs, defaultColDefs } = tableColumns(intl).archivePage;
  const gridApi = useRef(null);
  const columnApi = useRef(null);

  //  Fetch Events
  const fetchData = () => {
    const filters = loaderForm.getFieldsValue();
    dispatch(eventsSearch({ filters })).finally(() => {
      gridApi.current.paginationGoToFirstPage();
      gridApi.current.hideOverlay();
    });
  };

  const onGridReady = (params) => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;
    fetchData();
  };

  useEffect(() => {
    loaderForm.setFieldsValue({ fromTo: [moment(), moment().add(2, "years")]});
  }, []);

  const renderLabels = (statuses) => {
    return statuses.map((status) => {
      switch (status) {
        case "Confirmed":
          return (
            <div key={status} style={{ background: green.primary, ...style }}>
              {counters[status]} {intl.formatMessage({ id: status })}
            </div>
          );
        case "Cancelled":
          return (
            <div key={status} style={{ background: red.primary, ...style }}>
              {counters[status]} {intl.formatMessage({ id: status })}
            </div>
          );
        case "Provisionally Confirmed":
          return (
            <div key={status} style={{ background: cyan.primary, ...style }}>
              {counters[status]} {intl.formatMessage({ id: status })}
            </div>
          );
        case "Expired":
          return (
            <div key={status} style={{ background: volcano.primary, ...style }}>
              {counters[status]} {intl.formatMessage({ id: status })}
            </div>
          );
        case "Pending":
        case "Draft":
          return (
            <div key={status} style={{ background: grey[1], ...style }}>
              {counters[status]} {intl.formatMessage({ id: status })}
            </div>
          );
        case "Realized":
          return (
            <div key={status} style={{ background: lime.primary, ...style }}>
              {counters[status]} {intl.formatMessage({ id: status })}
            </div>
          );
        default:
          return (
            <div key={status} style={{ background: grey.primary, ...style }}>
              {counters[status]} {intl.formatMessage({ id: status })}
            </div>
          );
      }
    });
  };

  useEffect(() => {
    const formattedEvents = events.map((event) => {
      const files = [];
      _.get(event, "attachments", []).forEach((a) => a.files.forEach((f) => files.push(f)));
      return { ...event, files };
    });
    setRowData(formattedEvents);
    const newCounters = {
      total: events.length,
    };
    eventStatuses.forEach((s) => {
      newCounters[s.value] = events.filter((g) => g.status === s.value).length;
    });
    setCounters(newCounters);
  }, [events]);

  return (
    <>
      <div className="page-header-container">
        <PageHeader
          className="page-header"
          title={intl.formatMessage({ id: "events" })}
          ghost={false}
        />
      </div>
      <div>
        <Loader form={loaderForm} loading={false} loadData={fetchData} />
      </div>
      <div>
        <div
          style={{ display: "flex", flexDirection: "row", marginBottom: 24, alignItems: "center" }}
        >
          <div key="total" style={{ background: "black", ...style }}>
            {counters["total"]} {intl.formatMessage({ id: "total" })}
          </div>
          {renderLabels(
            eventStatuses.map((s) => s.value),
            counters
          )}
        </div>
        <ArchiveTable
          columnDefs={columnDefs}
          defaultColDefs={defaultColDefs}
          onGridReady={onGridReady}
          rowData={rowData}
        />
      </div>
    </>
  );
};

export default EventsArchivePage;
