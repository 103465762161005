import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { Button, PageHeader, Form } from "antd";

import EditProfile from "./partials/EditProfile";
import { updateCurrentUser } from "../../redux/actions";

const ProfilePage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const user = useSelector((state) => state.auth, shallowEqual);

  const [contacts, setContacts] = useState(user.contacts);

  const tableOperations = { onDataChanged: (data) => setContacts(data) };

  const onFinish = async () => {
    const values = form.getFieldsValue();
    const newUser = { contacts, ...values };
    dispatch(updateCurrentUser(newUser)).catch((err) => console.error(err));
  };

  useEffect(() => {
    form.setFieldsValue(user);
  }, []);

  return (
    <>
      <div className="page-header-container">
        <PageHeader
          className="page-header"
          title={intl.formatMessage({ id: "profile" })}
          ghost={false}
          extra={[
            <Button key="updateUser" type="primary" form="editCooperation" htmlType="submit">
              {intl.formatMessage({ id: "save" })}
            </Button>,
          ]}
        />
      </div>
      <EditProfile tableOperations={tableOperations} contacts={contacts} form={form} onFinish={onFinish} />
    </>
  );
};

export default ProfilePage;
