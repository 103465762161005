import { useEffect, useState } from "react";
import { connect, useSelector, shallowEqual } from "react-redux";
import {
  NavLink,
  Route,
  withRouter,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Button, Col, ConfigProvider, Layout, Menu, Row, Spin } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LockFilled,
} from "@ant-design/icons";
import moment from "moment";
import { FormattedMessage, IntlProvider } from "react-intl";
import _ from "lodash";

import Notification from "../components/system/Notification";
import RedirectComponent from "../components/system/Redirect";
import UserInfo from "../components/system/UserInfo";
import LogoutButton from "../components/system/LogoutButton";
import LanguagesDropdown from "../components/system/LanguagesDropdown";
import ForceReconnectToHotelsCRM from "../components/system/ForceReconnectToHotelsCRM";

//  Import moment Languages
import "moment/locale/en-gb";
import "moment/locale/el";

import * as appConfig from "../config";
import RequiresAuth from "../components/system/RequiresAuth";
import * as Pages from "./";
import { fetchPublicConfig } from "../redux/actions";
import PortalIDInfo from "../components/system/PortalIDInfo";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const Item = ({ to, children, exact = false, ...props }) => (
  <Menu.Item {...props}>
    <NavLink
      className="nav-text"
      activeClassName="active"
      to={to}
      exact={exact}
    >
      {children}
    </NavLink>
  </Menu.Item>
);

const renderMenuItemChildren = ({ icon, title }, isUser) => (
  <>
    {icon}
    <span>
      <FormattedMessage id={title} />
    </span>
    {isUser && <LockFilled style={{ marginLeft: 8, color: "#1890ff" }} />}
  </>
);

const renderMenuItem = (item, user) => {
  const isUser = item.admin && user.role !== "admin";
  if (isUser) {
    return;
  }
  return item.submenu ? (
    <SubMenu key={item.path} title={renderMenuItemChildren(item, isUser)}>
      {item.submenu.map(renderMenuItem)}
    </SubMenu>
  ) : (
    <Item key={item.path} to={item.path} exact={item.exact}>
      {item.children ? item.children : renderMenuItemChildren(item, isUser)}
    </Item>
  );
};

const MainPage = (props) => {
  //  Main Menu Location and collapse status
  const location = useLocation();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const [loadingPublicConfig, setLoadingPublicConfig] = useState(true);
  const { config } = props;
  const root = "/" + location.pathname.split("/")[1];

  //  Locale Info
  const [locale, setLocale] = useState(props.language.antd);
  const [intlLocaleId, setintlLocaleId] = useState(props.language.id);

  const user = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    setLocale(props.language.antd);
    moment.locale(props.language.moment);
    setintlLocaleId(props.language.id);
  }, [props.language]);

  useEffect(() => {
    if (_.isNil(props.config)) {
      props.fetchPublicConfig().then(() => setLoadingPublicConfig(false));
    } else {
      setLoadingPublicConfig(false);
    }
  }, []);

  const intlLocale = appConfig.languages.find((l) => l.id === intlLocaleId);

  if (loadingPublicConfig) {
    return (
      <div style={{ position: "fixed", top: "50%", left: "50%" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <IntlProvider
      locale={intlLocaleId}
      defaultLocale={intlLocaleId}
      key={intlLocaleId}
      messages={intlLocale.i18n}
    >
      <ConfigProvider locale={locale}>
        <Switch>
          <RequiresAuth path="/">
            <Layout id="mainPageLayout">
              <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className="logo">
                  <img src={_.get(config, "logo")} alt="HotelsCRM Logo" />
                </div>
                <Menu
                  id="topMenu"
                  theme="dark"
                  mode="inline"
                  //  defaultSelectedKeys={[root]}
                  defaultOpenKeys={[root]}
                  selectedKeys={[location.pathname]}
                >
                  {appConfig.menuItems.map((item) =>
                    renderMenuItem(item, user)
                  )}
                </Menu>
              </Sider>
              <Layout>
                <Header id="topHeader">
                  <Row>
                    <Col span={16}>
                      {collapsed ? (
                        <MenuUnfoldOutlined
                          className="headerMenu-IconBurger"
                          onClick={() => setCollapsed(!collapsed)}
                        />
                      ) : (
                        <MenuFoldOutlined
                          className="headerMenu-IconBurger"
                          onClick={() => setCollapsed(!collapsed)}
                        />
                      )}
                      <UserInfo />
                      <PortalIDInfo />
                      <Button
                        type="primary"
                        onClick={() => history.push("/reservations/create")}
                      >
                        Book Now
                      </Button>
                    </Col>
                    <Col span={8}>
                      <div id="topHeaderRightElements" className="right">
                        {user.username === "admin" && (
                          <ForceReconnectToHotelsCRM />
                        )}
                        <LogoutButton />
                        <LanguagesDropdown />
                      </div>
                    </Col>
                  </Row>
                </Header>
                <Content id="mainPageContent">
                  <Switch>
                    <Route path="/profile">
                      <Pages.ProfilePage />
                    </Route>
                    <Route path="/hotels">
                      <Pages.HotelsArchivePage />
                    </Route>
                    <Route path="/contracts">
                      <Pages.ContractsArchivePage />
                    </Route>
                    <Route path="/offers">
                      <Pages.OffersArchivePage />
                    </Route>
                    <Route path="/restrictions">
                      <Pages.RestrictionsArchivePage />
                    </Route>
                    <Route path="/reservations" exact={true}>
                      <Pages.ReservationsArchivePage />
                    </Route>
                    <Route path="/reservations/create">
                      <Pages.ReservationsEditPage />
                    </Route>
                    <Route path="/groups">
                      <Pages.GroupsArchivePage />
                    </Route>
                    <Route path="/events">
                      <Pages.EventsArchivePage />
                    </Route>
                    <Route path="/users">
                      <Pages.UsersPage />
                    </Route>
                    <Route path="/">
                      <Pages.ProfilePage />
                    </Route>
                  </Switch>
                </Content>
              </Layout>
            </Layout>
            <Notification />
            <RedirectComponent />
          </RequiresAuth>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.system.language,
    config: state.config,
  };
};

export default connect(mapStateToProps, { fetchPublicConfig })(
  withRouter(MainPage)
);
