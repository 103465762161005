import * as types from "../actions/types";

const initState = {
  contracts: [],
  events: [],
  groups: [],
  hotels: [],
  offers: [],
  reservations: [],
  restrictions: [],
};

const hotelsCRM = (state = initState, action) => {
  switch (action.type) {
    case types.CONTRACTS_SEARCH: {
      return { ...state, contracts: action.payload };
    }
    case types.EVENTS_SEARCH: {
      return { ...state, events: action.payload };
    }
    case types.GROUPS_SEARCH: {
      return { ...state, groups: action.payload };
    }
    case types.HOTELS_SEARCH: {
      return { ...state, hotels: action.payload };
    }
    case types.OFFERS_SEARCH: {
      return { ...state, offers: action.payload };
    }
    case types.RESERVATIONS_SEARCH: {
      return { ...state, reservations: action.payload };
    }
    case types.RESTRICTIONS_SEARCH: {
      return { ...state, restrictions: action.payload };
    }
    case types.CLEAR_DATA: {
      return initState;
    }
    default: {
      return state;
    }
  }
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { hotelsCRM };
