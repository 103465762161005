import { useIntl } from "react-intl";
import _ from "lodash";

import { Form, Select, Input, Row, Col } from "antd";

import EditableTable from "../../../components/table/EditableTable";
import tableColumns from "../constants/tableColumns";
import defaultValues from "../constants/defaultValues";
import { countries } from "../../../constants";

const { Option } = Select;

const EditProfile = ({ adminFields = false, contacts, form, tableOperations, onFinish }) => {
  const intl = useIntl();

  const isEdit = !_.isEmpty(form.getFieldValue("_id"));

  const { columnDefs: contactsColumnsDefs, defaultColDefs: contactsDefaultColumnDefs } =
    tableColumns(intl).contacts;

  //  Field Rules
  const requiredRule = { required: true, message: intl.formatMessage({ id: "fieldRequired" }) };
  const emailRule = { type: "email", message: intl.formatMessage({ id: "isNotValidEmail" }) };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Form
        layout="vertical"
        id="editCooperation"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={16}>
        {adminFields && (
            <>
              <Form.Item name="_id" hidden={true}>
                <Input />
              </Form.Item>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={intl.formatMessage({ id: "username" })}
                  name="username"
                  rules={[requiredRule]}
                >
                  <Input disabled={isEdit} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={intl.formatMessage({ id: "password" })}
                  name="password"
                  rules={isEdit ? [] : [requiredRule]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={intl.formatMessage({ id: "language" })}
                  name="language"
                  rules={[requiredRule]}
                >
                  <Select>
                    <Option value="el">Greek</Option>
                    <Option value="en">English</Option>
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
          <Col className="gutter-row" span={6}>
            <Form.Item
              label={intl.formatMessage({ id: "abbreviation" })}
              name="abbreviation"
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label={intl.formatMessage({ id: "officialName" })}
              name="officialName"
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item label={intl.formatMessage({ id: "country" })} name="country">
              <Select showSearch optionFilterProp="children">
                {countries.map((c) => (
                  <Option key={c.value} value={c.value}>
                    {c.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item label={intl.formatMessage({ id: "phone" })} name="phone">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <Form.Item label={intl.formatMessage({ id: "fax" })} name="fax">
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item label={intl.formatMessage({ id: "email" })} name="email" rules={[emailRule]}>
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item label={intl.formatMessage({ id: "website" })} name="website">
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item label={intl.formatMessage({ id: "address" })} name="address">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <Form.Item label={intl.formatMessage({ id: "vat" })} name="vat">
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item label={intl.formatMessage({ id: "he" })} name="he">
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item label={intl.formatMessage({ id: "licenceId" })} name="licenceId">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div className="ag-theme-balham editable-table">
        <h3>{intl.formatMessage({ id: "contacts" })}</h3>
        <EditableTable
          columnDefs={contactsColumnsDefs}
          defaultColDefs={contactsDefaultColumnDefs}
          defaultValues={defaultValues.contacts}
          rowData={contacts}
          property="contacts"
          tableOperations={tableOperations}
        />
      </div>
    </div>
  );
};

export default EditProfile;
