import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";

import { Button, Form, PageHeader } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { moment } from "../../helpers/moment";

import { reservationCreate, reservationFetch, sendNotification } from "../../redux/actions";
import EditReservation from "./partials/EditReservation";

const ReservationsEditPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const [savingData, setSavingData] = useState(false);
  const [rooms, setRooms] = useState([]);

  const reservation = useSelector(
    (state) => (id ? state.hotelsCRM.reservations.data[id] : {}),
    shallowEqual
  );

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      await form.validateFields();
      const formattedRooms = _.cloneDeep(rooms).map((r) => _.omit(r, ["_id"]));
      const data = { ...form.getFieldsValue(), rooms: formattedRooms };
      //  Find min date
      const dates = _.flatten(
        rooms.map((r) => [
          moment(r.arrival).startOf("day"),
          moment(r.departure).startOf("day"),
        ])
      );
      data.from = moment.min(dates);
      //  Find max date
      data.to = moment.max(dates);
      //  Find total amount
      data.amount = _.sumBy(rooms, "amount");
      //  Check if warnings exists in any of the rooms
      data.warnings = _.some(rooms, (i) => !_.isEmpty(i.warnings));
      setSavingData(true);
      dispatch(reservationCreate(data))
        .then(({ payload }) => {
          if (payload.stripeSessionURL) return window.location.href = payload.stripeSessionURL;
          history.push(`/reservations`)
        })
        .finally(() => setSavingData(false));
      
    } catch (errors) {
      dispatch(
        sendNotification({
          type: "error",
          message: intl.formatMessage({ id: "cannotCreateReservation" }),
          description: intl.formatMessage({ id: "checkErrors" }),
        })
      );
    }
  };

  useEffect(() => {
    if (!_.isEmpty(reservation)) {
      const initialValues = {
        ...reservation,
        ...reservation.reservationLeader,
      };
      delete initialValues.reservationLeader;
      initialValues.cooperation = initialValues.cooperation._id;
      form.setFieldsValue(initialValues);
    }
  }, [reservation]);

  useEffect(() => {
    if (id) dispatch(reservationFetch(id));
  }, []);

  return (
    <div>
      <div className="page-header-container">
        <PageHeader
          className="page-header"
          ghost={false}
          title={intl.formatMessage({ id: "reservationsCreate" })}
          extra={[
            <React.Fragment key="extra">
              <Button
                key="savingButton"
                type="primary"
                icon={<SaveOutlined />}
                loading={savingData}
                onClick={onSave}
              >
                {intl.formatMessage({ id: "create" })}
              </Button>
            </React.Fragment>,
          ]}
        />
      </div>
      <EditReservation form={form} id={id} rooms={rooms} setRooms={setRooms} />
    </div>
  );
};

export default ReservationsEditPage;
