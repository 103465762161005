import { useIntl } from "react-intl";
import { useSelector, shallowEqual } from "react-redux";
import { moment } from "../../../helpers/moment";

import { Button, Col, DatePicker, Form, Row, Select } from "antd";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Loader = ({ form, loading, loadData }) => {
  const intl = useIntl();

  const hotels = useSelector((state) => state.hotelsCRM.hotels, shallowEqual);

  return (
    <Form layout="vertical" name="events-loader" form={form} onFinish={loadData}>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={4}>
          <Form.Item name="_hotel" label={intl.formatMessage({ id: "hotel" })}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder={intl.formatMessage({ id: "selectHotel" })}
              allowClear
            >
              {hotels.map((hotel) => (
                <Option key={hotel._id} value={hotel._id}>
                  {hotel.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item name="fromTo" label={intl.formatMessage({ id: "from/to" })}>
            <RangePicker
              allowClear
              placeholder={[intl.formatMessage({ id: "from" }), intl.formatMessage({ id: "to" })]}
              style={{ width: "100%" }}
              format="DD/MM/YYYY"
              defaultValue={[moment(), moment().add(2, "years")]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={4} xxl={4}>
          <Form.Item label={intl.formatMessage({ id: "actions" })}>
            <Button disabled={loading} type="primary" htmlType="submit" style={{ marginRight: 8 }}>
              {intl.formatMessage({ id: "search" })}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Loader;
