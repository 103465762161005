import moment from "moment";
import _ from "lodash";

import translate from "../../../helpers/translate";
import  { hotelsCRMURI } from "../../../config";

const formatDate = ({ value }) => (value ? moment(value).format("LL") : "");
const formatDateTime = (data) => data.value && moment(data.value).format("DD/MM/YYYY HH:mm");
const multiObjNameRenderer = ({ value }) =>
  _.isArray(value) ? value.map((i) => i).join(", ") : "";

export default translate({
  archivePage: {
    columnDefs: [
      {
        headerName: "actions",
        field: "actions",
        pinned: "left",
        hide: false,
        cellRenderer: "actionsRenderer",
        cellRendererParams: (params) => {
          const { _hotel, _id, contractId, recipientId } = params.data;
          return {
            seenWarning: true,
            link: `${hotelsCRMURI}${_hotel.slug}/offer/${_id}/${contractId}/${recipientId}?portal=true`,
          };
        },
        width: 100,
        resizable: false,
        suppressSizeToFit: true,
        filter: false,
        sortable: false,
        floatingFilter: false,
      },
      {
        headerName: "hotel",
        field: "_hotel.name",
        pinned: "left",
      },
      {
        headerName: "contractId",
        field: "contractId",
        pinned: "left",
      },
      {
        headerName: "offerType",
        field: "offerType",
      },
      {
        headerName: "name",
        field: "name",
      },
      {
        headerName: "status",
        field: "status",
        cellRenderer: "statusRenderer",
      },
      {
        headerName: "reservationsFrom",
        field: "reservationDates.from",
        width: 80,
        cellRenderer: formatDate,
      },
      {
        headerName: "reservationsTo",
        field: "reservationDates.to",
        width: 80,
        cellRenderer: formatDate,
      },
      {
        headerName: "sent",
        field: "sent",
        width: 120,
        cellRenderer: "sentRenderer",
      },
      {
        headerName: "seen",
        field: "seen",
        width: 120,
        cellRenderer: "seenRenderer",
      },
      {
        headerName: "reservationCondition",
        field: "reservationCondition",
      },
      {
        headerName: "conditionFrom",
        field: "conditionDates.from",
        width: 80,
        cellRenderer: formatDate,
      },
      {
        headerName: "conditionTo",
        field: "conditionDates.to",
        width: 80,
        cellRenderer: formatDate,
      },
      {
        headerName: "combinable",
        field: "combinable",
        width: 80,
        cellRenderer: "booleanRenderer",
      },
      {
        headerName: "notes",
        field: "notes",
      },
      {
        headerName: "createdOn",
        field: "_createdOn",
        width: 120,
        cellRenderer: formatDateTime,
      },
      {
        headerName: "season",
        field: "season",
        cellRenderer: multiObjNameRenderer,
      },
      {
        headerName: "rateSegment",
        field: "rateSegment",
        cellRenderer: multiObjNameRenderer,
      },
      {
        headerName: "market",
        field: "market",
        cellRenderer: multiObjNameRenderer,
      },
    ],
    defaultColDefs: {
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
    },
  },
});
