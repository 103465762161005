import { useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PageHeader, Form } from "antd";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { offersSearch } from "../../redux/actions";
import tableColumns from "./constants/tableColumns";
import ArchiveTable from "../../components/table/ArchiveTable";
import Loader from "./partials/Loader";

const OffersArchivePage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const rowData = useSelector((state) => state.hotelsCRM.offers, shallowEqual);

  //  Loader Form
  const [loaderForm] = Form.useForm();

  const { columnDefs, defaultColDefs } = tableColumns(intl).archivePage;
  const gridApi = useRef(null);
  const columnApi = useRef(null);

  const fetchData = () => {
    const filters = loaderForm.getFieldsValue();
    gridApi.current.showLoadingOverlay();
    filters.offerTypes = ["Off Contract Offer"];
    return dispatch(offersSearch({ filters })).finally(() => {
      gridApi.current.paginationGoToFirstPage();
      gridApi.current.hideOverlay();
    });
  };

  const onGridReady = (params) => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;
    fetchData();
  };

  const formatRowData = (offers) => {
    const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const finalOffers = [];
    offers.forEach((offer) => {
      offer.contracts.forEach((contract) =>
        finalOffers.push({
          ...offer,
          contractId: contract,
        })
      );
    });

    return finalOffers.map((offer) => {
      if (offer.status !== "active") return offer;
      const { conditionDates } = offer;
      const fromDate = moment(conditionDates.from).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const toDate = moment(conditionDates.to).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const range = moment.range([fromDate, toDate]);
      let status;

      if (range.contains(today) || toDate.diff(today) >= 0) {
        status = "active";
      } else {
        status = "Expired";
      }
      return { ...offer, status };
    });
  };

  useEffect(() => {
    loaderForm.setFieldsValue({ conditionDates: [moment(), moment().add(2, "years")]});
  }, []);

  return (
    <>
      <div className="page-header-container">
        <PageHeader
          className="page-header"
          title={<FormattedMessage id="offers" />}
          ghost={false}
        />
      </div>
      <div>
        <Loader form={loaderForm} loading={false} loadData={fetchData} />
      </div>
      <div>
        <ArchiveTable
          columnDefs={columnDefs}
          defaultColDefs={defaultColDefs}
          onGridReady={onGridReady}
          rowData={formatRowData(rowData)}
          getRowNodeId={((data) => `${data._id}-${data.contractId}`)}
        />
      </div>
    </>
  );
};

export default OffersArchivePage;
