import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";

import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

const UserInfo = () => {
  const user = useSelector((state) => state.auth, shallowEqual);

  const renderAvatar = (user) => (
    <Avatar className="userAvatarDropdown" src={_.get(user, "photo.small")} icon={<UserOutlined />}>
      {_.get(user, "officialName", user.username).charAt(0).toUpperCase()}
    </Avatar>
  );

  return (
    <>
      {renderAvatar(user)}
      <p style={{ display: "inline-block", margin: "0 8px" }}>
        {_.get(user, "officialName", user.username)}
      </p>
    </>
  );
};

export default UserInfo;
