import React from "react";
import {
  AuditOutlined,
  BankOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  GroupOutlined,
  PercentageOutlined,
  StopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import * as pages from "../pages";

const menuItems = [
  // {
  //   path: "/",
  //   title: "home",
  //   icon: <HomeOutlined />,
  //   component: pages.HomePage,
  // },
  {
    path: "/profile",
    title: "profile",
    icon: <UserOutlined />,
    component: pages.ProfilePage,
  },
  {
    path: "/hotels",
    title: "hotels",
    icon: <BankOutlined />,
    component: pages.HotelsArchivePage,
  },
  {
    path: "/contracts",
    title: "contracts",
    icon: <AuditOutlined />,
    component: pages.ContractsArchivePage,
  },
  {
    path: "/offers",
    title: "offers",
    icon: <PercentageOutlined />,
    component: pages.OffersArchivePage,
  },
  {
    path: "/restrictions",
    title: "restrictions",
    icon: <StopOutlined />,
    component: pages.RestrictionsArchivePage,
  },
  {
    path: "/groups",
    title: "groups",
    icon: <GroupOutlined />,
    component: pages.GroupsArchivePage,
  },
  {
    path: "/events",
    title: "events",
    icon: <CalendarOutlined />,
    component: pages.EventsArchivePage,
  },
  {
    path: "/reservations",
    title: "reservations",
    icon: <CarryOutOutlined />,
    component: pages.ReservationsArchivePage,
  },
  {
    path: "/users",
    title: "users",
    icon: <UserOutlined />,
    component: pages.UsersPage,
    admin: true,
  },
];

export default menuItems;
