import { useIntl } from "react-intl";
import { Button, Tooltip } from "antd";
import { EyeOutlined, WarningTwoTone } from "@ant-design/icons";

const ActionsRenderer = ({ seenWarning, link, data }) => {
  const intl = useIntl();
  return (
    <>
      {link && (
        <Tooltip title={intl.formatMessage({ id: "view" })}>
          <a href={`${link}`} target="_blank" rel="noreferrer">
            <Button type="link" icon={<EyeOutlined />} />
          </a>
        </Tooltip>
      )}
      {(seenWarning && !data.seen) && (
        <Tooltip title={intl.formatMessage({ id: "youHaventSeenThisYet" })}>
          <Button type="link" icon={<WarningTwoTone twoToneColor="orange" />} />
        </Tooltip>
      )}
    </>
  );
};

export default ActionsRenderer;
