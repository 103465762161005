import { useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import _ from "lodash";
import { Form, PageHeader } from "antd";

import { contractsSearch } from "../../redux/actions";
import tableColumns from "./constants/tableColumns";
import ArchiveTable from "../../components/table/ArchiveTable";
import Loader from "./partials/Loader";

const ContractsArchivePage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  //  Loader Form
  const [loaderForm] = Form.useForm();

  //  Get Contracts from redux
  const contracts = useSelector((state) => state.hotelsCRM.contracts, shallowEqual);
  const rowData = contracts.map((contract) => {
    const files = [];
    _.get(contract, "attachments", []).forEach((a) => a.files.forEach((f) => files.push(f)));
    return { ...contract, files };
  });

  //  Table columns and table api
  const { columnDefs, defaultColDefs } = tableColumns(intl).archivePage;
  const gridApi = useRef(null);
  const columnApi = useRef(null);

  //  Fetch Contracts
  const fetchData = () => {
    const filters = loaderForm.getFieldsValue();
    gridApi.current.showLoadingOverlay();
    dispatch(contractsSearch({ filters })).finally(() => {
      gridApi.current.paginationGoToFirstPage();
      gridApi.current.hideOverlay();
    });
  };

  const onGridReady = (params) => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;
    fetchData();
  };

  return (
    <>
      <div className="page-header-container">
        <PageHeader
          className="page-header"
          title={intl.formatMessage({ id: "contracts" })}
          ghost={false}
        />
      </div>
      <div>
        <Loader form={loaderForm} loading={false} loadData={fetchData} />
      </div>
      <div>
        <ArchiveTable
          columnDefs={columnDefs}
          defaultColDefs={defaultColDefs}
          onGridReady={onGridReady}
          rowData={rowData}
        />
      </div>
    </>
  );
};

export default ContractsArchivePage;
