module.exports = {
  channelManagerRatePolicyStatuses: [
    {
      label: "active",
      value: "active",
    },
    {
      label: "inactive",
      value: "inactive",
    },
    {
      label: "draft",
      value: "draft",
    },
  ],
  channelManagerRestrictions: [
    {
      label: "Minimum Stay",
      value: "Minimum Stay",
    },
    {
      label: "Minimum Stay On Arrival",
      value: "Minimum Stay On Arrival",
    },
    {
      label: "Maximum Stay",
      value: "Maximum Stay",
    },
    {
      label: "Maximum Stay On Arrival",
      value: "Maximum Stay On Arrival",
    },
    {
      label: "Exact Stay",
      value: "Exact Stay",
    },
    {
      label: "Exact Stay On Arrival",
      value: "Exact Stay On Arrival",
    },
    {
      label: "Release Days",
      value: "Release Days",
    },
  ],
  channelManagerSupplementsAndReductions: [
    {
      label: "Single Occupancy",
      value: "singleOccupancy",
    },
    {
      label: "First Extra Adult",
      value: "extraAdult1",
    },
    {
      label: "Second+ Extra Adult",
      value: "extraAdult2",
    },
    {
      label: "Child A",
      value: "childA",
    },
    {
      label: "Child B",
      value: "childB",
    },
    {
      label: "Infant",
      value: "infant",
    },
  ],
  colors: [
    {
      label: "Red",
      value: "#f5222d",
    },
    {
      label: "Volcano",
      value: "#fa541c",
    },
    {
      label: "Orange",
      value: "#fa8c16",
    },
    {
      label: "Gold",
      value: "#faad14",
    },
    {
      label: "Yellow",
      value: "#fadb14",
    },
    {
      label: "Lime",
      value: "#a0d911",
    },
    {
      label: "Green",
      value: "#52c41a",
    },
    {
      label: "Cyan",
      value: "#13c2c2",
    },
    {
      label: "Blue",
      value: "#1890ff",
    },
    {
      label: "Geek Blue",
      value: "#2f54eb",
    },
    {
      label: "Purple",
      value: "#722ed1",
    },
    {
      label: "Magenta",
      value: "#eb2f96",
    },
    {
      label: "Gray",
      value: "#bfbfbf",
    },
    {
      label: "Light Gray",
      value: "#f5f5f5",
    },
    {
      label: "Black",
      value: "#000000",
    },
  ],
  commissionBasedOn: [
    {
      label: "termsBooked",
      value: "termsBooked",
    },
    {
      label: "baseTerm",
      value: "baseTerm",
    },
  ],
  conditionOptions: [
    {
      label: "creation",
      value: "creation",
    },
    {
      label: "stays",
      value: "stays",
    },
    {
      label: "arrivals",
      value: "arrivals",
    },
    {
      label: "departures",
      value: "departures",
    },
  ],
  contactRoles: [
    {
      label: "Contractor",
      value: "Contractor",
    },
    {
      label: "Group Coordinator",
      value: "Group Coordinator",
    },
    {
      label: "Event Coordinator",
      value: "Event Coordinator",
    },
  ],
  contactTitles: [
    {
      label: "Mr",
      value: "Mr",
    },
    {
      label: "Ms",
      value: "Ms",
    },
    {
      label: "Mrs",
      value: "Mrs",
    },
    {
      label: "Miss",
      value: "Miss",
    },
    {
      label: "All",
      value: "All",
    },
  ],
  contractStatuses: [
    {
      label: "active",
      value: "active",
    },
    {
      label: "inactive",
      value: "inactive",
    },
    {
      label: "draft",
      value: "draft",
    },
    {
      label: "blocked",
      value: "blocked",
    },
  ],
  contractTypes: [
    {
      label: "Allotment",
      value: "Allotment",
    },
    {
      label: "Commitment",
      value: "Commitment",
    },
    {
      label: "On Request",
      value: "On Request",
    },
    {
      label: "Turn Over Guarantee",
      value: "Turn Over Guarantee",
    },
    {
      label: "Performance Guarantee",
      value: "Performance Guarantee",
    },
  ],
  contributionDeductionOn: [
    {
      label: "from invoice",
      value: "from invoice",
    },
    {
      label: "end of season",
      value: "end of season",
    },
  ],
  contributions: [
    {
      label: "Agent",
      value: "Agent",
    },
    {
      label: "Brochure",
      value: "Brochure",
    },
    {
      label: "Custom",
      value: "Custom",
    },
    {
      label: "Marketing",
      value: "Marketing",
    },
    {
      label: "Reps",
      value: "Reps",
    },
  ],
  cooperationEmailNotifications: [
    {
      label: "Cooperations",
      value: "Cooperations",
    },
    {
      label: "Contracts",
      value: "Contracts",
    },
    {
      label: "Offers",
      value: "Offers",
    },
    {
      label: "Promotions",
      value: "Promotions",
    },
    {
      label: "Restrictions",
      value: "Restrictions",
    },
    {
      label: "Groups",
      value: "Groups",
    },
    {
      label: "Events",
      value: "Events",
    },
    {
      label: "Invoices",
      value: "Invoices",
    },
    {
      label: "Reservations",
      value: "Reservations",
    },
    {
      label: "Support",
      value: "Support",
    },
    {
      label: "No Show",
      value: "No Show",
    },
    {
      label: "Incentive Programs",
      value: "Incentive Programs",
    },
  ],
  countries: [
    {
      label: "Afghanistan",
      value: "AF",
    },
    {
      label: "Åland Islands",
      value: "AX",
    },
    {
      label: "Albania",
      value: "AL",
    },
    {
      label: "Algeria",
      value: "DZ",
    },
    {
      label: "American Samoa",
      value: "AS",
    },
    {
      label: "AndorrA",
      value: "AD",
    },
    {
      label: "Angola",
      value: "AO",
    },
    {
      label: "Anguilla",
      value: "AI",
    },
    {
      label: "Antarctica",
      value: "AQ",
    },
    {
      label: "Antigua and Barbuda",
      value: "AG",
    },
    {
      label: "Argentina",
      value: "AR",
    },
    {
      label: "Armenia",
      value: "AM",
    },
    {
      label: "Aruba",
      value: "AW",
    },
    {
      label: "Australia",
      value: "AU",
    },
    {
      label: "Austria",
      value: "AT",
    },
    {
      label: "Azerbaijan",
      value: "AZ",
    },
    {
      label: "Bahamas",
      value: "BS",
    },
    {
      label: "Bahrain",
      value: "BH",
    },
    {
      label: "Bangladesh",
      value: "BD",
    },
    {
      label: "Barbados",
      value: "BB",
    },
    {
      label: "Belarus",
      value: "BY",
    },
    {
      label: "Belgium",
      value: "BE",
    },
    {
      label: "Belize",
      value: "BZ",
    },
    {
      label: "Benin",
      value: "BJ",
    },
    {
      label: "Bermuda",
      value: "BM",
    },
    {
      label: "Bhutan",
      value: "BT",
    },
    {
      label: "Bolivia",
      value: "BO",
    },
    {
      label: "Bosnia and Herzegovina",
      value: "BA",
    },
    {
      label: "Botswana",
      value: "BW",
    },
    {
      label: "Bouvet Island",
      value: "BV",
    },
    {
      label: "Brazil",
      value: "BR",
    },
    {
      label: "British Indian Ocean Territory",
      value: "IO",
    },
    {
      label: "Brunei Darussalam",
      value: "BN",
    },
    {
      label: "Bulgaria",
      value: "BG",
    },
    {
      label: "Burkina Faso",
      value: "BF",
    },
    {
      label: "Burundi",
      value: "BI",
    },
    {
      label: "Cambodia",
      value: "KH",
    },
    {
      label: "Cameroon",
      value: "CM",
    },
    {
      label: "Canada",
      value: "CA",
    },
    {
      label: "Cape Verde",
      value: "CV",
    },
    {
      label: "Cayman Islands",
      value: "KY",
    },
    {
      label: "Central African Republic",
      value: "CF",
    },
    {
      label: "Chad",
      value: "TD",
    },
    {
      label: "Chile",
      value: "CL",
    },
    {
      label: "China",
      value: "CN",
    },
    {
      label: "Christmas Island",
      value: "CX",
    },
    {
      label: "Cocos (Keeling) Islands",
      value: "CC",
    },
    {
      label: "Colombia",
      value: "CO",
    },
    {
      label: "Comoros",
      value: "KM",
    },
    {
      label: "Congo",
      value: "CG",
    },
    {
      label: "Congo, The Democratic Republic of the",
      value: "CD",
    },
    {
      label: "Cook Islands",
      value: "CK",
    },
    {
      label: "Costa Rica",
      value: "CR",
    },
    {
      label: "Cote D'Ivoire",
      value: "CI",
    },
    {
      label: "Croatia",
      value: "HR",
    },
    {
      label: "Cuba",
      value: "CU",
    },
    {
      label: "Cyprus",
      value: "CY",
    },
    {
      label: "Czech Republic",
      value: "CZ",
    },
    {
      label: "Denmark",
      value: "DK",
    },
    {
      label: "Djibouti",
      value: "DJ",
    },
    {
      label: "Dominica",
      value: "DM",
    },
    {
      label: "Dominican Republic",
      value: "DO",
    },
    {
      label: "Ecuador",
      value: "EC",
    },
    {
      label: "Egypt",
      value: "EG",
    },
    {
      label: "El Salvador",
      value: "SV",
    },
    {
      label: "Equatorial Guinea",
      value: "GQ",
    },
    {
      label: "Eritrea",
      value: "ER",
    },
    {
      label: "Estonia",
      value: "EE",
    },
    {
      label: "Ethiopia",
      value: "ET",
    },
    {
      label: "Falkland Islands (Malvinas)",
      value: "FK",
    },
    {
      label: "Faroe Islands",
      value: "FO",
    },
    {
      label: "Fiji",
      value: "FJ",
    },
    {
      label: "Finland",
      value: "FI",
    },
    {
      label: "France",
      value: "FR",
    },
    {
      label: "French Guiana",
      value: "GF",
    },
    {
      label: "French Polynesia",
      value: "PF",
    },
    {
      label: "French Southern Territories",
      value: "TF",
    },
    {
      label: "Gabon",
      value: "GA",
    },
    {
      label: "Gambia",
      value: "GM",
    },
    {
      label: "Georgia",
      value: "GE",
    },
    {
      label: "Germany",
      value: "DE",
    },
    {
      label: "Ghana",
      value: "GH",
    },
    {
      label: "Gibraltar",
      value: "GI",
    },
    {
      label: "Greece",
      value: "GR",
    },
    {
      label: "Greenland",
      value: "GL",
    },
    {
      label: "Grenada",
      value: "GD",
    },
    {
      label: "Guadeloupe",
      value: "GP",
    },
    {
      label: "Guam",
      value: "GU",
    },
    {
      label: "Guatemala",
      value: "GT",
    },
    {
      label: "Guernsey",
      value: "GG",
    },
    {
      label: "Guinea",
      value: "GN",
    },
    {
      label: "Guinea-Bissau",
      value: "GW",
    },
    {
      label: "Guyana",
      value: "GY",
    },
    {
      label: "Haiti",
      value: "HT",
    },
    {
      label: "Heard Island and Mcdonald Islands",
      value: "HM",
    },
    {
      label: "Holy See (Vatican City State)",
      value: "VA",
    },
    {
      label: "Honduras",
      value: "HN",
    },
    {
      label: "Hong Kong",
      value: "HK",
    },
    {
      label: "Hungary",
      value: "HU",
    },
    {
      label: "Iceland",
      value: "IS",
    },
    {
      label: "India",
      value: "IN",
    },
    {
      label: "Indonesia",
      value: "ID",
    },
    {
      label: "Iran, Islamic Republic Of",
      value: "IR",
    },
    {
      label: "Iraq",
      value: "IQ",
    },
    {
      label: "Ireland",
      value: "IE",
    },
    {
      label: "Isle of Man",
      value: "IM",
    },
    {
      label: "Israel",
      value: "IL",
    },
    {
      label: "Italy",
      value: "IT",
    },
    {
      label: "Jamaica",
      value: "JM",
    },
    {
      label: "Japan",
      value: "JP",
    },
    {
      label: "Jersey",
      value: "JE",
    },
    {
      label: "Jordan",
      value: "JO",
    },
    {
      label: "Kazakhstan",
      value: "KZ",
    },
    {
      label: "Kenya",
      value: "KE",
    },
    {
      label: "Kiribati",
      value: "KI",
    },
    {
      label: "Korea, Democratic People'S Republic of",
      value: "KP",
    },
    {
      label: "Korea, Republic of",
      value: "KR",
    },
    {
      label: "Kuwait",
      value: "KW",
    },
    {
      label: "Kyrgyzstan",
      value: "KG",
    },
    {
      label: "Lao People'S Democratic Republic",
      value: "LA",
    },
    {
      label: "Latvia",
      value: "LV",
    },
    {
      label: "Lebanon",
      value: "LB",
    },
    {
      label: "Lesotho",
      value: "LS",
    },
    {
      label: "Liberia",
      value: "LR",
    },
    {
      label: "Libyan Arab Jamahiriya",
      value: "LY",
    },
    {
      label: "Liechtenstein",
      value: "LI",
    },
    {
      label: "Lithuania",
      value: "LT",
    },
    {
      label: "Luxembourg",
      value: "LU",
    },
    {
      label: "Macao",
      value: "MO",
    },
    {
      label: "Macedonia, The Former Yugoslav Republic of",
      value: "MK",
    },
    {
      label: "Madagascar",
      value: "MG",
    },
    {
      label: "Malawi",
      value: "MW",
    },
    {
      label: "Malaysia",
      value: "MY",
    },
    {
      label: "Maldives",
      value: "MV",
    },
    {
      label: "Mali",
      value: "ML",
    },
    {
      label: "Malta",
      value: "MT",
    },
    {
      label: "Marshall Islands",
      value: "MH",
    },
    {
      label: "Martinique",
      value: "MQ",
    },
    {
      label: "Mauritania",
      value: "MR",
    },
    {
      label: "Mauritius",
      value: "MU",
    },
    {
      label: "Mayotte",
      value: "YT",
    },
    {
      label: "Mexico",
      value: "MX",
    },
    {
      label: "Micronesia, Federated States of",
      value: "FM",
    },
    {
      label: "Moldova, Republic of",
      value: "MD",
    },
    {
      label: "Monaco",
      value: "MC",
    },
    {
      label: "Mongolia",
      value: "MN",
    },
    {
      label: "Montserrat",
      value: "MS",
    },
    {
      label: "Morocco",
      value: "MA",
    },
    {
      label: "Mozambique",
      value: "MZ",
    },
    {
      label: "Myanmar",
      value: "MM",
    },
    {
      label: "Namibia",
      value: "NA",
    },
    {
      label: "Nauru",
      value: "NR",
    },
    {
      label: "Nepal",
      value: "NP",
    },
    {
      label: "Netherlands",
      value: "NL",
    },
    {
      label: "Netherlands Antilles",
      value: "AN",
    },
    {
      label: "New Caledonia",
      value: "NC",
    },
    {
      label: "New Zealand",
      value: "NZ",
    },
    {
      label: "Nicaragua",
      value: "NI",
    },
    {
      label: "Niger",
      value: "NE",
    },
    {
      label: "Nigeria",
      value: "NG",
    },
    {
      label: "Niue",
      value: "NU",
    },
    {
      label: "Norfolk Island",
      value: "NF",
    },
    {
      label: "Northern Mariana Islands",
      value: "MP",
    },
    {
      label: "Norway",
      value: "NO",
    },
    {
      label: "Oman",
      value: "OM",
    },
    {
      label: "Pakistan",
      value: "PK",
    },
    {
      label: "Palau",
      value: "PW",
    },
    {
      label: "Palestinian Territory, Occupied",
      value: "PS",
    },
    {
      label: "Panama",
      value: "PA",
    },
    {
      label: "Papua New Guinea",
      value: "PG",
    },
    {
      label: "Paraguay",
      value: "PY",
    },
    {
      label: "Peru",
      value: "PE",
    },
    {
      label: "Philippines",
      value: "PH",
    },
    {
      label: "Pitcairn",
      value: "PN",
    },
    {
      label: "Poland",
      value: "PL",
    },
    {
      label: "Portugal",
      value: "PT",
    },
    {
      label: "Puerto Rico",
      value: "PR",
    },
    {
      label: "Qatar",
      value: "QA",
    },
    {
      label: "Reunion",
      value: "RE",
    },
    {
      label: "Romania",
      value: "RO",
    },
    {
      label: "Russian Federation",
      value: "RU",
    },
    {
      label: "RWANDA",
      value: "RW",
    },
    {
      label: "Saint Helena",
      value: "SH",
    },
    {
      label: "Saint Kitts and Nevis",
      value: "KN",
    },
    {
      label: "Saint Lucia",
      value: "LC",
    },
    {
      label: "Saint Pierre and Miquelon",
      value: "PM",
    },
    {
      label: "Saint Vincent and the Grenadines",
      value: "VC",
    },
    {
      label: "Samoa",
      value: "WS",
    },
    {
      label: "San Marino",
      value: "SM",
    },
    {
      label: "Sao Tome and Principe",
      value: "ST",
    },
    {
      label: "Saudi Arabia",
      value: "SA",
    },
    {
      label: "Senegal",
      value: "SN",
    },
    {
      label: "Serbia and Montenegro",
      value: "CS",
    },
    {
      label: "Seychelles",
      value: "SC",
    },
    {
      label: "Sierra Leone",
      value: "SL",
    },
    {
      label: "Singapore",
      value: "SG",
    },
    {
      label: "Slovakia",
      value: "SK",
    },
    {
      label: "Slovenia",
      value: "SI",
    },
    {
      label: "Solomon Islands",
      value: "SB",
    },
    {
      label: "Somalia",
      value: "SO",
    },
    {
      label: "South Africa",
      value: "ZA",
    },
    {
      label: "South Georgia and the South Sandwich Islands",
      value: "GS",
    },
    {
      label: "Spain",
      value: "ES",
    },
    {
      label: "Sri Lanka",
      value: "LK",
    },
    {
      label: "Sudan",
      value: "SD",
    },
    {
      label: "Suriname",
      value: "SR",
    },
    {
      label: "Svalbard and Jan Mayen",
      value: "SJ",
    },
    {
      label: "Swaziland",
      value: "SZ",
    },
    {
      label: "Sweden",
      value: "SE",
    },
    {
      label: "Switzerland",
      value: "CH",
    },
    {
      label: "Syrian Arab Republic",
      value: "SY",
    },
    {
      label: "Taiwan, Province of China",
      value: "TW",
    },
    {
      label: "Tajikistan",
      value: "TJ",
    },
    {
      label: "Tanzania, United Republic of",
      value: "TZ",
    },
    {
      label: "Thailand",
      value: "TH",
    },
    {
      label: "Timor-Leste",
      value: "TL",
    },
    {
      label: "Togo",
      value: "TG",
    },
    {
      label: "Tokelau",
      value: "TK",
    },
    {
      label: "Tonga",
      value: "TO",
    },
    {
      label: "Trinidad and Tobago",
      value: "TT",
    },
    {
      label: "Tunisia",
      value: "TN",
    },
    {
      label: "Turkey",
      value: "TR",
    },
    {
      label: "Turkmenistan",
      value: "TM",
    },
    {
      label: "Turks and Caicos Islands",
      value: "TC",
    },
    {
      label: "Tuvalu",
      value: "TV",
    },
    {
      label: "Uganda",
      value: "UG",
    },
    {
      label: "Ukraine",
      value: "UA",
    },
    {
      label: "United Arab Emirates",
      value: "AE",
    },
    {
      label: "United Kingdom",
      value: "GB",
    },
    {
      label: "United States",
      value: "US",
    },
    {
      label: "United States Minor Outlying Islands",
      value: "UM",
    },
    {
      label: "Uruguay",
      value: "UY",
    },
    {
      label: "Uzbekistan",
      value: "UZ",
    },
    {
      label: "Vanuatu",
      value: "VU",
    },
    {
      label: "Venezuela",
      value: "VE",
    },
    {
      label: "Viet Nam",
      value: "VN",
    },
    {
      label: "Virgin Islands, British",
      value: "VG",
    },
    {
      label: "Virgin Islands, U.S.",
      value: "VI",
    },
    {
      label: "Wallis and Futuna",
      value: "WF",
    },
    {
      label: "Western Sahara",
      value: "EH",
    },
    {
      label: "Yemen",
      value: "YE",
    },
    {
      label: "Zambia",
      value: "ZM",
    },
    {
      label: "Zimbabwe",
      value: "ZW",
    },
  ],
  currencies: [
    {
      label: "Dollar",
      symbol: "$",
      value: "Dollar",
    },
    {
      label: "Euro",
      symbol: "€",
      value: "Euro",
    },
    {
      label: "Pound Sterling",
      symbol: "£",
      value: "Pound Sterling",
    },
  ],
  days: [
    {
      label: "Monday",
      value: 1,
    },
    {
      label: "Tuesday",
      value: 2,
    },
    {
      label: "Wednesday",
      value: 3,
    },
    {
      label: "Thursday",
      value: 4,
    },
    {
      label: "Friday",
      value: 5,
    },
    {
      label: "Saturday",
      value: 6,
    },
    {
      label: "Sunday",
      value: 0,
    },
  ],
  emailNotifications: [
    {
      label: "Billing",
      value: "Billing",
    },
    {
      label: "Cooperations",
      value: "Cooperations",
    },
    {
      label: "Contracts",
      value: "Contracts",
    },
    {
      label: "Offers",
      value: "Offers",
    },
    {
      label: "Promotions",
      value: "Promotions",
    },
    {
      label: "Restrictions",
      value: "Restrictions",
    },
    {
      label: "Group Updated",
      value: "Group Updated",
    },
    {
      label: "Group Payment",
      value: "Group Payment",
    },
    {
      label: "Group Comment",
      value: "Group Comment",
    },
    {
      label: "Group Created",
      value: "Group Created",
    },
    {
      label: "Event Updated",
      value: "Event Updated",
    },
    {
      label: "Event Payment",
      value: "Event Payment",
    },
    {
      label: "Event Comment",
      value: "Event Comment",
    },
    {
      label: "Event Created",
      value: "Event Created",
    },
    {
      label: "Invoices",
      value: "Invoices",
    },
    {
      label: "Reservations",
      value: "Reservations",
    },
    {
      label: "Support",
      value: "Support",
    },
    {
      label: "No Show",
      value: "No Show",
    },
    {
      label: "Incentive Programs",
      value: "Incentive Programs",
    },
  ],
  eventStatuses: [
    {
      label: "Cancelled",
      value: "Cancelled",
    },
    {
      label: "Out Of Availability",
      value: "Out Of Availability",
    },
    {
      label: "Confirmed",
      value: "Confirmed",
    },
    {
      label: "Pending",
      value: "Pending",
    },
    {
      label: "Provisionally Confirmed",
      value: "Provisionally Confirmed",
    },
    {
      label: "Realized",
      value: "Realized",
    },
  ],
  fileFolders: [
    {
      label: "setup",
      value: "setup",
    },
    {
      label: "contracts",
      value: "contracts",
    },
    {
      label: "promotions",
      value: "promotions",
    },
    {
      label: "issues",
      value: "issues",
    },
    {
      label: "email",
      value: "email",
    },
    {
      label: "projects",
      value: "projects",
    },
    {
      label: "tasks",
      value: "tasks",
    },
    {
      label: "users",
      value: "users",
    },
    {
      label: "fileExplorer",
      value: "fileExplorer",
    },
  ],
  guestTypes: [{
    label: "adult",
    value: "adult"
  }, {
    label: "child",
    value: "child"
  }, {
    label: "infant",
    value: "infant",
  }],
  groupStatuses: [
    {
      label: "Cancelled",
      value: "Cancelled",
    },
    {
      label: "Out Of Availability",
      value: "Out Of Availability",
    },
    {
      label: "Confirmed",
      value: "Confirmed",
    },
    {
      label: "Pending",
      value: "Pending",
    },
    {
      label: "Provisionally Confirmed",
      value: "Provisionally Confirmed",
    },
    {
      label: "Realized",
      value: "Realized",
    },
  ],
  hotelCategories: [
    {
      label: "Apartments",
      value: "Apartments",
    },
    {
      label: "Boutique Hotel",
      value: "Boutique Hotel",
    },
    {
      label: "Holiday Village",
      value: "Holiday Village",
    },
    {
      label: "Hotel",
      value: "Hotel",
    },
    {
      label: "Villa",
      value: "Villa",
    },
  ],
  issueStatus: [
    {
      label: "unresolved",
      value: "unresolved",
    },
    {
      label: "ignored",
      value: "ignored",
    },
    {
      label: "inReview",
      value: "inReview",
    },
    {
      label: "resolved",
      value: "resolved",
    },
  ],
  mimetypes: [
    {
      label: "image",
      value: "image",
    },
    {
      label: "spreadsheet",
      value: "spreadsheet",
    },
    {
      label: "document",
      value: "document",
    },
    {
      label: "video",
      value: "video",
    },
    {
      label: "audio",
      value: "audio",
    },
    {
      label: "pdf",
      value: "pdf",
    },
    {
      label: "archive",
      value: "archive",
    },
  ],
  offerCustomFields: [
    {
      label: "Minimum Stay",
      value: "minimumStay",
    },
    {
      label: "Maximum Stay",
      value: "maximumStay",
    },
    {
      label: "Minimum Age",
      value: "minimumAge",
    },
    {
      label: "Days Before Arrival",
      value: "daysBeforeArrival",
    },
    {
      label: "Pay Nights",
      value: "payNights",
    },
    {
      label: "Get Free Nights",
      value: "getFreeNights",
    },
    {
      label: "Pay Term",
      value: "payTerm",
    },
    {
      label: "Get Free Term",
      value: "getFreeTerm",
    },
    {
      label: "Discount",
      value: "discount",
    },
    {
      label: "Get Occupancy Room",
      value: "getOccupancyRoom",
    },
    {
      label: "Pay Occupancy Room",
      value: "payOccupancyRoom",
    },
  ],
  offerReservationConditions: [
    {
      label: "Arrivals",
      value: "Arrivals",
    },
    {
      label: "Completed Holidays",
      value: "Completed Holidays",
    },
    {
      label: "Stays",
      value: "Stays",
    },
  ],
  offerSources: [
    {
      label: "Off Contract Offer",
      value: "Off Contract Offer",
    },
    {
      label: "Contract Offer",
      value: "Contract Offer",
    },
    {
      label: "Rate Policy Offer",
      value: "Rate Policy Offer",
    },
  ],
  offerStatuses: [
    {
      label: "active",
      value: "active",
    },
    {
      label: "draft",
      value: "draft",
    },
    {
      label: "inactive",
      value: "inactive",
    },
  ],
  officialRatings: [
    {
      label: "1 Star",
      value: "1 Star",
    },
    {
      label: "2 Star",
      value: "2 Star",
    },
    {
      label: "3 Star",
      value: "3 Star",
    },
    {
      label: "4 Star",
      value: "4 Star",
    },
    {
      label: "5 Star",
      value: "5 Star",
    },
    {
      label: "Cat A",
      value: "Cat A",
    },
    {
      label: "Cat B",
      value: "Cat B",
    },
    {
      label: "Cat C",
      value: "Cat C",
    },
    {
      label: "Pending",
      value: "Pending",
    },
  ],
  paymentTime: [
    {
      label: "After Departure",
      value: "After Departure",
    },
    {
      label: "Before Arrival",
      value: "Before Arrival",
    },
    {
      label: "Before Departure",
      value: "Before Departure",
    },
    {
      label: "By Reservation",
      value: "By Reservation",
    },
    {
      label: "Upon Arrival",
      value: "Upon Arrival",
    },
    {
      label: "Upon Confirmation",
      value: "Upon Confirmation",
    },
  ],
  pricePer: [
    {
      label: "Person",
      value: "Person",
    },
    {
      label: "Unit",
      value: "Unit",
    },
  ],
  promotionStatuses: [
    {
      label: "active",
      value: "active",
    },
    {
      label: "draft",
      value: "draft",
    },
    {
      label: "expired",
      value: "expired",
    },
    {
      label: "inactive",
      value: "inactive",
    },
  ],
  reservationSources: [
    {
      label: "Smartlink",
      value: "Smartlink",
    },
    {
      label: "HotelsCRM",
      value: "HotelsCRM",
    },
    {
      label: "HotelsCRM Portal",
      value: "HotelsCRM Portal",
    },
  ],
  reservationStatuses: [
    {
      label: "cancelled",
      value: "Cancelled",
    },
    {
      label: "active",
      value: "Active",
    },
    {
      label: "pending",
      value: "Pending",
    },
  ],
  restrictions: [
    {
      label: "Minimum Stay",
      value: "Minimum Stay",
    },
    {
      label: "Maximum Stay",
      value: "Maximum Stay",
    },
    {
      label: "Release Period",
      value: "Release Period",
    },
    {
      label: "Stop Sales",
      value: "Stop Sales",
    },
    {
      label: "Open Sales",
      value: "Open Sales",
    },
    {
      label: "Set Allotment",
      value: "Set Allotment",
    },
  ],
  smartlinkReservationConditions: [
    {
      label: "creationDate",
      value: "creation",
    },
    {
      label: "arrivals",
      value: "arrivals",
    },
    {
      label: "departures",
      value: "departures",
    },
    {
      label: "stays",
      value: "stays",
    },
  ],
  stripe_PK:
    "pk_live_51JD7isKYwXKsCM2vKPq1rFeF8uu5frUeDYl1s9NH0thx9qnuDUhNgddDLdXofe3pX9ihBsVekGob4mD2nIbftua100cmbQXNGZ",
  taskStatuses: [
    {
      label: "pending",
      value: "pending",
    },
    {
      label: "inProgress",
      value: "inProgress",
    },
    {
      label: "done",
      value: "done",
    },
  ],
  valueTypeOptions: [
    {
      label: "value",
      value: "value",
    },
    {
      label: "percentage",
      value: "percentage",
    },
  ],
  valueTypeOptionsWithDays: [
    {
      label: "value",
      value: "value",
    },
    {
      label: "percentage",
      value: "percentage",
    },
    {
      label: "days",
      value: "days",
    },
  ],
};
