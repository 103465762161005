import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";

//  Custom Renderers
import ActionsRenderer from "./renderers/ActionsRenderer";
import BooleanRenderer from "./renderers/BooleanRenderer";
import FilesRenderer from "./renderers/FilesRenderer";
import LinkRenderer from "./renderers/LinkRenderer";
import PublicURLRenderer from "./renderers/PublicURLRenderer";
import SeenRenderer from "./renderers/SeenRenderer";
import SentRenderer from "./renderers/SentRenderer";
import StatusRenderer from "./renderers/StatusRenderer";

// Editors
import FilesEditor from "./editors/FilesEditor";

const ArchiveTable = (props) => {
  const {
    columnDefs,
    defaultColDefs,
    onGridReady,
    rowData,
    rowSelection,
    gridOptions = {},
    getRowNodeId,
  } = props;

  function autoSizeAll(params, skipHeader) {
    const allColumnIds = params.columnApi.getAllColumns().map((column) => column.getColId());
    setTimeout(() => params.columnApi.autoSizeColumns(allColumnIds, skipHeader), 10);
  }

  return (
    <div>
      <div
        id="agGrid"
        className="ag-theme-balham"
        style={{ flex: 1, height: 600, paddingTop: 12, paddingBottom: 24 }}
      >
        <AgGridReact
          suppressAnimationFrame={true}
          suppressExcelExport={true}
          defaultColDef={defaultColDefs}
          columnDefs={columnDefs}
          rowData={rowData}
          modules={AllCommunityModules}
          enableCellTextSelection={true}
          ensureDomOrder={true}
          multiSortKey={"ctrl"}
          rowSelection={rowSelection}
          suppressRowClickSelection={true}
          pagination={false}
          rowBuffer={100}
          paginationAutoPageSize={true}
          getRowNodeId={getRowNodeId || ((data) => data._id)}
          frameworkComponents={{
            actionsRenderer: ActionsRenderer,
            booleanRenderer: BooleanRenderer,
            filesRenderer: FilesRenderer,
            linkRenderer: LinkRenderer,
            publicURLRenderer: PublicURLRenderer,
            seenRenderer: SeenRenderer,
            sentRenderer: SentRenderer,
            statusRenderer: StatusRenderer,
            //  Editors
            filesEditor: FilesEditor,
          }}
          onGridReady={(params) => (onGridReady ? onGridReady(params) : null)}
          onFirstDataRendered={(params) => autoSizeAll(params, false)}
          onGridColumnsChanged={(params) => autoSizeAll(params, false)}
          onSelectionChanged={gridOptions.onSelectionChanged}
          onRowDataChanged={gridOptions.onRowDataChanged}
          onFilterChanged={gridOptions.onFilterChanged}
        />
      </div>
    </div>
  );
};

export default ArchiveTable;
