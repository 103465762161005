import _ from "lodash";

const translate = (data) => (intl) => {
  if (!intl) return data;
  const newData = _.cloneDeep(data);
  Object.values(newData).forEach((value) => {
    value.columnDefs = value.columnDefs.map((column) => ({
      ...column,
      headerName: intl.formatMessage({ id: column.headerName }),
    }));
  });
  return newData;
};

export default translate;
