import { useIntl } from "react-intl";
import { useSelector, shallowEqual } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Input, notification, Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";

const PortalIDInfo = () => {
  const intl = useIntl();
  const { _portalId } = useSelector((state) => state.auth, shallowEqual);
  return (
    <div style={{ display: "inline-block", marginRight: 12 }}>
      <Input
        value={_portalId}
        style={{ width: 295, background: "white", color: "black", cursor: "pointer" }}
        disabled={true}
        suffix={
          <Tooltip title={intl.formatMessage({ id: "copyPortalIdToClipboard" })}>
            <CopyToClipboard
              text={_portalId}
              onCopy={() =>
                notification.success({
                  message: intl.formatMessage({ id: "portalIdCopiedToClipboard" }),
                })
              }
            >
              <CopyOutlined />
            </CopyToClipboard>
          </Tooltip>
        }
      />
    </div>
  );
};

export default PortalIDInfo;
