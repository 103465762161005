import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import _ from "lodash";

import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import { moment } from "../../../helpers/moment";
import { contactTitles, countries, currencies, guestTypes } from "../../../constants";
import { contractsSearch, reservationsGetPrice } from "../../../redux/actions";
import PriceAnalysisModal from "./PriceAnalysisModal";
import { getCurrentAndFuture10YSeasons } from "../../../helpers";

const { RangePicker } = DatePicker;
const { Option } = Select;

const convertToInt = (v) => ~~v;

const EditRoomModal = ({ room, form, hotel, isVisible, onOk, onCancel }) => {
  const isEdit = room ? true : false;
  const dispatch = useDispatch();
  const intl = useIntl();

  //  Local State
  const [messages, setMessages] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [priceOptions, setPriceOptions] = useState([]);
  const [selectedContractOffer, setSelectedContractOffer] = useState();
  const [numOfChildren, setNumOfChildren] = useState(0);
  const [numOfInfants, setNumOfInfants] = useState(0);
  const [priceAnalysisModalVisible, setPriceAnalysisModalVisible] =
    useState(false);
  const [selectedRoom, setSelectedRoom] = useState();
  const [selectedContract, setSelectedContract] = useState();
  const [isContractsLoading, setIsContractsLoading] = useState(false);
  const [contractOccupancyRooms, setContractOccupancyRooms] = useState([]);
  const [contractBoardTerms, setContractBoardTerms] = useState([]);

  const requiredRule = {
    required: true,
    message: intl.formatMessage({ id: "fieldRequired" }),
  };

  const contracts = useSelector(
    (state) => state.hotelsCRM.contracts,
    shallowEqual
  );

  const occupancyRooms = contractOccupancyRooms
    .map((occupancyRoomId) =>
      hotel.rooms.occupancyRooms.find((i) => i._id === occupancyRoomId)
    )
    .filter((i) => i?.active);
  const boardTerms = contractBoardTerms
    .map((boardTerm) => hotel.boardTerms.find((i) => i._id === boardTerm))
    .filter((i) => i?.active);
  const currency = currencies.find((c) => c.value === hotel.currency);

  const currencySymbol = _.get(currency, "symbol", "");
  const resetState = async () => {
    setMessages([]);
    setWarnings([]);
    setPriceOptions([]);
    setSelectedContractOffer(undefined);
    setNumOfChildren(0);
  };

  const handleOnCheckAvailability = async () => {
    resetState();
    try {
      await form.validateFields(["contract", "fromTo", "numOfRooms", "occupancyRoom", "boardTerm"]);
      const data = await form.getFieldsValue();
      data.arrival = moment(data.fromTo[0]).format("YYYY-MM-DD");
      data.departure = moment(data.fromTo[1]).format("YYYY-MM-DD");
      delete data.fromTo;
      if (!data.children) data.children = [];
      const finalData = {
        ...data,
        contractId: selectedContract._id,
        _hotel: hotel._id,
      };
      setLoading(true);
      dispatch(reservationsGetPrice(finalData))
        .then(({ payload }) => {
          if (payload.error) return setMessages(payload.messages);
          const availableWarnings = payload.map((c) => c.warnings);
          setWarnings(_(availableWarnings).flatten().uniq().value());
          const formattedPayload = payload.map(
            ({ contractId: contract, ...rest }) => ({
              contract,
              ...rest,
            })
          );
          setPriceOptions(formattedPayload);
        })
        .catch(({ err }) => console.error(err))
        .finally(() => setLoading(false));
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleAddRoom = async () => {
    await form.validateFields();
    const priceOption = priceOptions.find(
      (o) => o._id === selectedContractOffer
    );
    const { remarks, specialRoomRequests, guests } = form.getFieldsValue();
    onOk({ ...priceOption, remarks, specialRoomRequests, guests });
  };

  const handleOnChangeContract = (contractId) => {
    if (!contractId) return setSelectedContract(undefined);
    resetState();
    const contract = contracts.find((contract) => contract._id === contractId);
    form.setFieldsValue({
      contract: contract._id,
    });
    setSelectedContract(contract);
    const occupancyRooms = _.uniq(
      _.get(contract, "roomRates", []).map((r) => r.occupancyRoom)
    );
    const boardTerms = _.uniq([
      contract.baseBoardTermId,
      ..._.get(contract, "termSupplements", []).map((i) => i.boardTerm),
    ]);
    const selectedOccupancyRoom = form.getFieldValue("occupancyRoom");
    const selectedBoardTerm = form.getFieldValue("boardTerm");
    if (!occupancyRooms.includes(selectedOccupancyRoom)) {
      form.setFieldsValue({ occupancyRoom: null });
    }
    if (!boardTerms.includes(selectedBoardTerm)) {
      form.setFieldsValue({ boardTerm: null });
    }
    setContractOccupancyRooms(occupancyRooms);
    setContractBoardTerms(boardTerms);
  };

  const columns = [
    {
      title: "Offer Name",
      dataIndex: "name",
      render: (value) => <span style={{ fontWeight: 600 }}>{value}</span>,
      sorter: (a, b) => {
        if (b.name === "Free Cancellation") return 0;
        if (a.name < b.name) return -1;
        if (b.name < a.name) return 1;
        return 0;
      },
    },
    {
      title: "Offer Details",
      dataIndex: "miniDescription",
      className: "room-offers-table-column",
    },
    {
      title: "Price",
      dataIndex: "amount",
      defaultSortOrder: "descend",
      fixed: "right",
      width: 140,
      render: (text) => `${currencySymbol}${text}`,
      sorter: (a, b) => {
        if (b.name === "Free Cancellation") return 0;
        return a.amount - b.amount;
      },
    },
    {
      title: "Analysis",
      key: "priceAnalysis",
      render: (text, record) => (
        <Button
          type="link"
          icon={<InfoCircleOutlined />}
          onClick={() => {
            setSelectedRoom(record);
            setPriceAnalysisModalVisible(true);
          }}
        >
          View
        </Button>
      ),
      width: 140,
      fixed: "right",
    },
  ];

  useEffect(() => {
    resetState();
    setNumOfInfants(form.getFieldValue("infants"));
  }, [isVisible]);

  useEffect(() => {
    setIsContractsLoading(true);
    const filters = {
      _hotel: hotel._id,
      "bookingEngine.enabled": true,
      season: { $in: getCurrentAndFuture10YSeasons(hotel.seasons) },
    };
    dispatch(contractsSearch({ filters })).finally(() =>
      setIsContractsLoading(false)
    );
  }, [hotel]);

  useEffect(() => {
    if (!_.isEmpty(room)) {
      handleOnChangeContract(room.contract);
    }
  }, [room]);

  const handleOnCancel = () => {
    setSelectedContract(null);
    onCancel();
  };

  return (
    <Modal
      title={intl.formatMessage({ id: isEdit ? "editRoom" : "addRoom" })}
      visible={isVisible}
      onCancel={handleOnCancel}
      width={1400}
      style={{ top: 12 }}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={handleOnCancel}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button
            type="primary"
            disabled={!selectedContractOffer}
            onClick={() => handleAddRoom()}
          >
            {intl.formatMessage({ id: isEdit ? "editRoom" : "addRoom" })}
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical" initialValues={{ guests: [{ }] }}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={10} lg={10}>
            <Form.Item
              name="contract"
              label={intl.formatMessage({ id: "contract" })}
              rules={[requiredRule]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                filterSort={(optionA, optionB) =>
                  optionB.children
                    .toLowerCase()
                    .localeCompare(optionA.children.toLowerCase())
                }
                onChange={handleOnChangeContract}
                loading={isContractsLoading}
              >
                {contracts.map((contract) => (
                  <Option key={contract._id} value={contract._id}>
                    {`${contract.rateSegment} - ${contract.season} - ${contract.market} (${contract.contractType})`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {selectedContract && (
            <>
              <Col>
                <Form.Item
                  name="fromTo"
                  label={intl.formatMessage({ id: "arrival/departure" })}
                  rules={[requiredRule]}
                >
                  <RangePicker
                    format="DD/MM/YYYY"
                    style={{ width: 300 }}
                    ranges={{
                      Today: [moment().startOf("day"), moment().startOf("day")],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    disabledDate={(current) =>
                      current < moment().startOf("day")
                    }
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="numOfRooms"
                  label={intl.formatMessage({ id: "numOfRooms" })}
                  rules={[requiredRule]}
                >
                  <InputNumber
                    style={{ width: 150 }}
                    min={1}
                    formatter={convertToInt}
                    parser={convertToInt}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="occupancyRoom"
                  label={intl.formatMessage({ id: "occupancyRoom" })}
                  rules={[requiredRule]}
                >
                  <Select style={{ width: 300 }}>
                    {occupancyRooms.map((o) => (
                      <Option key={o._id} value={o._id}>
                        {o.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        {selectedContract && (
          <>
            <Row gutter={16}>
              <Col>
                <Form.Item
                  name="boardTerm"
                  label={intl.formatMessage({ id: "boardTerm" })}
                  rules={[requiredRule]}
                >
                  <Select style={{ width: 300 }}>
                    {boardTerms.map((b) => (
                      <Select.Option key={b._id} value={b._id}>
                        {b.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="adults"
                  label={intl.formatMessage({ id: "adults" })}
                  rules={[requiredRule]}
                >
                  <InputNumber
                    style={{ width: 150 }}
                    min={0}
                    formatter={convertToInt}
                    parser={convertToInt}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.List layout="horizontal" name="children">
                  {(fields, { add, remove }, { errors }) => (
                    <Space direction="horizontal" size="small">
                      {fields.map((field, index) => (
                        <Form.Item
                          {...field}
                          label={`${intl.formatMessage({ id: "child" })} ${
                            index + 1
                          } (${intl.formatMessage({ id: "age" })}: ${_.get(
                            selectedContract,
                            "bookingEngine.childAge.from"
                          )} - ${_.get(
                            selectedContract,
                            "bookingEngine.childAge.to"
                          )})`}
                          key={field.key}
                          rules={[requiredRule]}
                        >
                          <InputNumber
                            addonAfter={
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => {
                                  setNumOfChildren(numOfChildren - 1);
                                  remove(field.name);
                                }}
                              />
                            }
                            min={_.get(
                              selectedContract,
                              "bookingEngine.childAge.from"
                            )}
                            max={_.get(
                              selectedContract,
                              "bookingEngine.childAge.to"
                            )}
                            formatter={convertToInt}
                            parser={convertToInt}
                            style={{ width: 150 }}
                            defaultValue=""
                          />
                        </Form.Item>
                      ))}
                      {fields.length < 5 && (
                        <Form.Item label=" ">
                          <Button
                            type="primary"
                            onClick={() => {
                              setNumOfChildren(numOfChildren + 1);
                              add();
                            }}
                            icon={<PlusOutlined />}
                          >
                            {intl.formatMessage({ id: "addChild" })}
                          </Button>
                        </Form.Item>
                      )}

                      <Form.ErrorList errors={errors} />
                    </Space>
                  )}
                </Form.List>
              </Col>
              {numOfInfants > 0 && (
                <Col>
                  <Form.Item
                    name="infants"
                    label={`${intl.formatMessage({
                      id: "infants",
                    })} (${intl.formatMessage({
                      id: "age",
                    })}: ${_.get(
                      selectedContract,
                      "bookingEngine.infantAge.from"
                    )} - ${_.get(
                      selectedContract,
                      "bookingEngine.infantAge.to"
                    )})`}
                  >
                    <InputNumber
                      style={{ width: 150 }}
                      min={0}
                      formatter={convertToInt}
                      parser={convertToInt}
                      onChange={(value) => setNumOfInfants(value)}
                      addonAfter={
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => {
                            form.setFieldsValue({ infants: 0 });
                            setNumOfInfants(0);
                          }}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
              )}
              <Col>
                <Form.Item label=" ">
                  <Button
                    type="primary"
                    onClick={() => {
                      form.setFieldsValue({ infants: 1 });
                      setNumOfInfants(1);
                    }}
                    icon={<PlusOutlined />}
                    disabled={numOfInfants > 0}
                  >
                    {intl.formatMessage({ id: "addInfant" })}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <div style={{ marginBottom: 16, display: "flex", justifyContent: "center" }}>
              <Button
                size="large"
                style={{ backgroundColor: "#52c41a", borderColor: "#389e0d", width: "50%" }}
                type="primary"
                onClick={handleOnCheckAvailability}
                loading={loading}
              >
                <span style={{ fontSize: 18, fontWeight: 600 }}>
                  {intl.formatMessage({ id: "checkAvailabilityAndRates" })}
                </span>
              </Button>
            </div>
            {(!_.isEmpty(warnings) || !_.isEmpty(messages)) && (
              <>
                <h3>{intl.formatMessage({ id: "errorsAndWarnings" })}</h3>
                <div
                  style={{
                    marginBottom: 16,
                    maxHeight: 166,
                    overflowY: "auto",
                  }}
                >
                  <Space direction="vertical" style={{ width: "100%" }}>
                    {messages.map((m, index) => (
                      <Alert key={index} message={m} type="error" showIcon />
                    ))}
                    {warnings.map((w, index) => (
                      <Alert key={index} message={w} type="warning" showIcon />
                    ))}
                  </Space>
                </div>
              </>
            )}
            {selectedRoom && (
              <PriceAnalysisModal
                isModalVisible={priceAnalysisModalVisible}
                onCancel={() => setPriceAnalysisModalVisible(false)}
                data={selectedRoom}
                hotel={hotel}
              />
            )}
            <Row gutter={16}>
              <Col span={24}>
                <h3>{intl.formatMessage({ id: "selectOneOfferBelow" })}</h3>
                <div className="room-offers-table">
                  <Table
                    bordered
                    columns={columns}
                    dataSource={priceOptions}
                    onRow={(record) => {
                      return {
                        onClick: () => setSelectedContractOffer(record._id),
                      };
                    }}
                    pagination={false}
                    rowClassName={(r) =>
                      r.name === "Free Cancellation"
                        ? "free-cancellation-row"
                        : null
                    }
                    rowKey="_id"
                    rowSelection={{
                      fixed: true,
                      onSelect: (r) => setSelectedContractOffer(r._id),
                      type: "radio",
                      selectedRowKeys: selectedContractOffer
                        ? [selectedContractOffer]
                        : [],
                    }}
                    size="small"
                    expandable={{
                      showExpandColumn: false,
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 16 }}>
              <Col span={24}>
                <Form.List name="guests">
                  {(fields, { add, remove }) => (
                    <>
                      <div className="ant-col ant-form-item-label" style={{ width: "100%" }}>
                        <Space>
                          <span>{intl.formatMessage({ id: "guests" })}</span>
                          <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />}>
                            {intl.formatMessage({ id: "addGuest" })}
                          </Button>
                        </Space>
                      </div>
                      {fields.map((field) => (
                        <Row gutter={[8, 8]} key={field.key}>
                          <Col xs={24} sm={24} md={4} lg={2}>
                            <Form.Item
                              {...field}
                              label={intl.formatMessage({ id: "title" })}
                              name={[field.name, "title"]}
                              rules={[requiredRule]}
                            >
                              <Select>
                                {contactTitles.map((b) => (
                                  <Select.Option key={b.value} value={b.value}>
                                    {b.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={6} lg={4}>
                            <Form.Item
                              {...field}
                              label={intl.formatMessage({ id: "firstName" })}
                              name={[field.name, "firstName"]}
                              rules={[requiredRule]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={6} lg={4}>
                            <Form.Item
                              {...field}
                              label={intl.formatMessage({ id: "lastName" })}
                              name={[field.name, "lastName"]}
                              rules={[requiredRule]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={6} lg={4}>
                            <Form.Item
                              {...field}
                              label={intl.formatMessage({ id: "email" })}
                              name={[field.name, "email"]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={6} lg={4}>
                            <Form.Item
                              {...field}
                              label={intl.formatMessage({ id: "phone" })}
                              name={[field.name, "phone"]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={6} lg={2}>
                            <Form.Item
                              {...field}
                              label={intl.formatMessage({ id: "type" })}
                              name={[field.name, "type"]}
                              rules={[requiredRule]}
                            >
                              <Select showSearch optionFilterProp="children">
                                {guestTypes.map((type) => (
                                  <Option key={type.value} value={type.value}>
                                    {intl.formatMessage({ id: type.label })}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={10} lg={3}>
                            <Form.Item
                              {...field}
                              label={intl.formatMessage({ id: "country" })}
                              name={[field.name, "countryCode"]}
                            >
                              <Select showSearch optionFilterProp="children">
                                {countries.map((country) => (
                                  <Option key={country.value} value={country.value}>
                                    {country.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={1}>
                            {fields.length > 1 && (
                              <Form.Item label=" ">
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                              </Form.Item>
                            )}
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                </Form.List>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={intl.formatMessage({ id: "specialRoomRequests" })}
                  name="specialRoomRequests"
                >
                  <Select
                    allowSearch
                    allowClear
                    mode="multiple"
                    placeholder={intl.formatMessage({
                      id: "selectSpecialRoomRequests",
                    })}
                  >
                    {hotel.specialRoomRequests
                      .filter((r) => r.active)
                      .map((r) => (
                        <Option key={r.name} value={r.name}>
                          {r.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  label={intl.formatMessage({ id: "remarks" })}
                  name="remarks"
                >
                  <Input.TextArea rows={1} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default EditRoomModal;
