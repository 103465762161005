import { combineReducers } from "redux";
import * as types from "../actions/types";

// Reducers
import auth from "./auth";
import config from "./config";
import hotelsCRM from "./hotelsCRM";
import system from "./system";

const appReducer = combineReducers({
  ...auth,
  ...config,
  ...hotelsCRM,
  ...system,
});

const reducers = (state, action) => {
  if (action.type === types.RESET) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default reducers;
