import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import _ from "lodash";

import { Popover, Tooltip } from "antd";
import { cyan, lime, orange, red, volcano, yellow } from "@ant-design/colors";

import Calendar from "../../../../components/Calendar";
import { restrictions } from "../../../../constants/index";

const restrictionTypes = restrictions.map((r) => r.value);

const style = { padding: "4px 8px", color: "white", fontSize: 10, marginRight: 4, fontWeight: 700 };
const STS = <div style={{ background: red.primary, ...style }}>STS</div>;
const OPS = <div style={{ background: cyan.primary, ...style }}>OPS</div>;
const MNS = <div style={{ background: volcano[4], ...style }}>MNS</div>;
const MXS = <div style={{ background: orange.primary, ...style }}>MXS</div>;
const REL = <div style={{ background: yellow.primary, ...style }}>REL</div>;
const ALL = <div style={{ background: lime.primary, ...style }}>ALL</div>;

const RestrictionsCalendar = ({ events, contract }) => {
  const intl = useIntl();
  const history = useHistory();
  const onDoubleClickEvent = (event) => {
    history.push(`/restrictions}`);
  };
  const eventData = (data) => {
    if (_.isEmpty(data)) return [];
    const events = [];
    restrictionTypes.forEach((restrictionType) => {
      Object.entries(data[restrictionType]).forEach(([occupancyRoomId, value]) => {
        Object.entries(value).forEach(([date, event]) => {
          const { occupancyRoom, number, restrictionType } = event;
          let label;
          let numberString = number ? ` [${number}] ` : " ";
          const populatedOccupancyRoom = contract?.occupancyRooms.find(
            (r) => r._id === occupancyRoom
          );
          switch (restrictionType[0]) {
            case "Minimum Stay":
              label = MNS;
              break;
            case "Maximum Stay":
              label = MXS;
              break;
            case "Release Period":
              label = REL;
              break;
            case "Set Allotment":
              label = ALL;
              break;
            case "Stop Sales":
              label = STS;
              break;
            case "Open Sales":
              label = OPS;
              break;
            default:
              return (label = null);
          }
          const title = (
            <div
              style={{ display: "flex", alignItems: "center", color: "white", fontWeight: "700" }}
            >
              {label}
              <span style={{ background: "#00000014", padding: "0px 8px" }}>
                {numberString}
                {populatedOccupancyRoom?.name}{" "}
              </span>
            </div>
          );
          const content = (
            <div>
              <div>
                <b>{intl.formatMessage({ id: "number" })}:</b> {event.number}
              </div>
              <div>
                <b>{intl.formatMessage({ id: "notes" })}:</b> {event.notes}
              </div>
            </div>
          );
          events.push({
            ...event,
            title: (
              <Popover content={content} title={title} trigger="click">
                {title}
              </Popover>
            ),
            start: new Date(date),
            end: new Date(date),
            allDay: true,
          });
        });
      });
    });
    return events;
  };

  const eventPropGetter = (event, start, end, isSelected) => {
    let restrictionTypeBGColor = "transparent";
    switch (event.restrictionType[0]) {
      case "Minimum Stay":
        restrictionTypeBGColor = volcano[4];
        break;
      case "Maximum Stay":
        restrictionTypeBGColor = orange.primary;
        break;
      case "Release Period":
        restrictionTypeBGColor = yellow.primary;
        break;
      case "Set Allotment":
        restrictionTypeBGColor = lime.primary;
        break;
      case "Stop Sales":
        restrictionTypeBGColor = red.primary;
        break;
      case "Open Sales":
        restrictionTypeBGColor = cyan.primary;
        break;
      default:
        restrictionTypeBGColor = "transparent";
    }
    const backgroundColor = isSelected ? "#d9f7be" : restrictionTypeBGColor;
    const style = {
      backgroundColor,
      borderRadius: 0,
      color: "black",
      border: 0,
      display: "block",
      fontSize: 12,
    };
    return { style };
  };

  return (
    <div className="restrictionsCalendar">
      <div style={{ display: "flex", marginBottom: 24, alignItems: "center" }}>
        <Tooltip title={intl.formatMessage({ id: "Stop Sales" })}>{STS}</Tooltip>
        <Tooltip title={intl.formatMessage({ id: "Open Sales" })}>{OPS}</Tooltip>
        <Tooltip title={intl.formatMessage({ id: "Minimum Stay" })}>{MNS}</Tooltip>
        <Tooltip title={intl.formatMessage({ id: "Maximum Stay" })}>{MXS}</Tooltip>
        <Tooltip title={intl.formatMessage({ id: "Release Period" })}>{REL}</Tooltip>
        <Tooltip title={intl.formatMessage({ id: "Set Allotment" })}>{ALL}</Tooltip>
      </div>
      <Calendar
        events={contract ? eventData(events) : []}
        onDoubleClickEvent={onDoubleClickEvent}
        eventPropGetter={eventPropGetter}
        views={["month", "week"]}
      />
    </div>
  );
};

export default RestrictionsCalendar;
